$mpds_visavis_elements_list $mol_object
	prop_names *
		num \atomic number
		nump \periodic number
		size \atomic size
		rea \atomic reactivity
		rpp \pseudopotential radii
		rion \ionic radii
		rcov \covalent radii
		rmet \metallic radii
		tmelt \melting temperature
		eneg \electronegativity
	list /
		*
			name \null
			num 0
			nump 0
			size 0
			rea 0
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0
			eneg 0
		*
			name \H
			num 1
			nump 105
			size 0.040000098
			rea 2.953092434
			rpp 1.25
			rion 0
			rcov 30
			rmet 0.78
			tmelt 0.003664921
			eneg 3.69
		*
			name \He
			num 2
			nump 112
			size 0.05525814
			rea 2.137675759
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.00026178
			eneg 6.29
		*
			name \Li
			num 3
			nump 1
			size 0.32352134
			rea 0.365119614
			rpp 1.61
			rion 0.6
			rcov 123
			rmet 1.562
			tmelt 0.118586387
			eneg 2.32
		*
			name \Be
			num 4
			nump 75
			size 0.149871021
			rea 0.788170962
			rpp 1.08
			rion 0.3
			rcov 89
			rmet 1.128
			tmelt 0.405759162
			eneg 3.71
		*
			name \B
			num 5
			nump 81
			size 0.15316946
			rea 0.771198036
			rpp 0.795
			rion 0.2
			rcov 88
			rmet 0.98
			tmelt 0.673560209
			eneg 4.88
		*
			name \C
			num 6
			nump 87
			size 0.152079019
			rea 0.776727701
			rpp 0.64
			rion 0.15
			rcov 77
			rmet 0.916
			tmelt 1
			eneg 6.08
		*
			name \N
			num 7
			nump 93
			size 0.147837836
			rea 0.799010527
			rpp 0.54
			rion 0.12
			rcov 70
			rmet 0.88
			tmelt 0.016492147
			eneg 7.31
		*
			name \O
			num 8
			nump 99
			size 0.141252647
			rea 0.836260342
			rpp 0.465
			rion 0.1
			rcov 66
			rmet 0.89
			tmelt 0.014397906
			eneg 8.5
		*
			name \F
			num 9
			nump 106
			size 0.130400994
			rea 0.905851889
			rpp 0.405
			rion 0.09
			rcov 58
			rmet 0
			tmelt 0.014136126
			eneg 9.7
		*
			name \Ne
			num 10
			nump 113
			size 0.118123987
			rea 0.999999998
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.006544503
			eneg 10.92
		*
			name \Na
			num 11
			nump 2
			size 0.578463822
			rea 0.204202895
			rpp 2.65
			rion 0.96
			rcov 0
			rmet 1.911
			tmelt 0.097120419
			eneg 2.27
		*
			name \Mg
			num 12
			nump 76
			size 0.235527361
			rea 0.501529786
			rpp 2.03
			rion 0.63
			rcov 136
			rmet 1.602
			tmelt 0.241361257
			eneg 3.37
		*
			name \Al
			num 13
			nump 82
			size 0.222322819
			rea 0.531317421
			rpp 1.675
			rion 0.5
			rcov 125
			rmet 1.432
			tmelt 0.244240838
			eneg 4.21
		*
			name \Si
			num 14
			nump 88
			size 0.208407341
			rea 0.56679379
			rpp 1.42
			rion 0.42
			rcov 117
			rmet 1.319
			tmelt 0.440575916
			eneg 5.08
		*
			name \P
			num 15
			nump 94
			size 0.19392461
			rea 0.609123241
			rpp 1.24
			rion 0.36
			rcov 110
			rmet 1.28
			tmelt 0.082984293
			eneg 5.95
		*
			name \S
			num 16
			nump 100
			size 0.178988166
			rea 0.659954172
			rpp 1.1
			rion 0.32
			rcov 104
			rmet 1.27
			tmelt 0.10104712
			eneg 6.79
		*
			name \Cl
			num 17
			nump 107
			size 0.160596861
			rea 0.735531107
			rpp 1.01
			rion 0.28
			rcov 99
			rmet 0
			tmelt 0.045026178
			eneg 7.64
		*
			name \Ar
			num 18
			nump 114
			size 0.14201091
			rea 0.831795156
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.021989529
			eneg 8.5
		*
			name \K
			num 19
			nump 3
			size 0.692195698
			rea 0.170651143
			rpp 3.69
			rion 1.33
			rcov 203
			rmet 2.376
			tmelt 0.088219895
			eneg 2.08
		*
			name \Ca
			num 20
			nump 7
			size 0.671411055
			rea 0.175933932
			rpp 3
			rion 0.96
			rcov 174
			rmet 1.974
			tmelt 0.290837696
			eneg 3
		*
			name \Sc
			num 21
			nump 11
			size 0.64709144
			rea 0.182546051
			rpp 2.75
			rion 0.8
			rcov 144
			rmet 1.941
			tmelt 0.47434555
			eneg 3.11
		*
			name \Ti
			num 22
			nump 43
			size 0.440998616
			rea 0.267855686
			rpp 2.58
			rion 0.68
			rcov 132
			rmet 1.462
			tmelt 0.506020942
			eneg 3.19
		*
			name \V
			num 23
			nump 47
			size 0.425579654
			rea 0.27756023
			rpp 2.43
			rion 0.65
			rcov 0
			rmet 1.346
			tmelt 0.566230366
			eneg 3.27
		*
			name \Cr
			num 24
			nump 51
			size 0.410235863
			rea 0.28794164
			rpp 2.44
			rion 0.62
			rcov 0
			rmet 1.36
			tmelt 0.557591623
			eneg 3.41
		*
			name \Mn
			num 25
			nump 55
			size 0.394967358
			rea 0.299072783
			rpp 2.22
			rion 0.6
			rcov 117
			rmet 1.304
			tmelt 0.397382199
			eneg 3.4
		*
			name \Fe
			num 26
			nump 59
			size 0.379774576
			rea 0.311037111
			rpp 2.11
			rion 0.59
			rcov 116
			rmet 1.274
			tmelt 0.473298429
			eneg 3.47
		*
			name \Co
			num 27
			nump 63
			size 0.364658122
			rea 0.323930772
			rpp 2.02
			rion 0.62
			rcov 116
			rmet 1.252
			tmelt 0.462827225
			eneg 3.53
		*
			name \Ni
			num 28
			nump 67
			size 0.349618659
			rea 0.337865225
			rpp 2.18
			rion 0.59
			rcov 115
			rmet 1.246
			tmelt 0.451832461
			eneg 3.59
		*
			name \Cu
			num 29
			nump 71
			size 0.334656835
			rea 0.352970489
			rpp 2.04
			rion 0.96
			rcov 117
			rmet 1.278
			tmelt 0.354973822
			eneg 3.74
		*
			name \Zn
			num 30
			nump 77
			size 0.310912262
			rea 0.379927077
			rpp 1.88
			rion 0.78
			rcov 125
			rmet 1.394
			tmelt 0.181413613
			eneg 3.7
		*
			name \Ga
			num 31
			nump 83
			size 0.287688252
			rea 0.410597187
			rpp 1.695
			rion 0.63
			rcov 125
			rmet 1.411
			tmelt 0.079319372
			eneg 4.37
		*
			name \Ge
			num 32
			nump 89
			size 0.264934676
			rea 0.445860802
			rpp 1.56
			rion 0.53
			rcov 122
			rmet 1.369
			tmelt 0.317015707
			eneg 5.09
		*
			name \As
			num 33
			nump 95
			size 0.242609734
			rea 0.486888901
			rpp 1.415
			rion 0.46
			rcov 121
			rmet 1.39
			tmelt 0.285340314
			eneg 5.82
		*
			name \Se
			num 34
			nump 101
			size 0.220678182
			rea 0.535277144
			rpp 1.285
			rion 0.41
			rcov 117
			rmet 1.4
			tmelt 0.128272251
			eneg 6.53
		*
			name \Br
			num 35
			nump 108
			size 0.195297025
			rea 0.604842736
			rpp 1.2
			rion 0.37
			rcov 114
			rmet 0
			tmelt 0.069633508
			eneg 7.21
		*
			name \Kr
			num 36
			nump 115
			size 0.170450145
			rea 0.693011946
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.030628272
			eneg 7.93
		*
			name \Rb
			num 37
			nump 4
			size 0.832019702
			rea 0.141972584
			rpp 4.1
			rion 1.49
			rcov 217
			rmet 2.546
			tmelt 0.081675393
			eneg 2.04
		*
			name \Sr
			num 38
			nump 8
			size 0.797564264
			rea 0.148105917
			rpp 3.21
			rion 1.11
			rcov 192
			rmet 2.151
			tmelt 0.273036649
			eneg 2.89
		*
			name \Y
			num 39
			nump 12
			size 0.762242103
			rea 0.154969119
			rpp 2.94
			rion 0.93
			rcov 162
			rmet 1.801
			tmelt 0.470157068
			eneg 3.04
		*
			name \Zr
			num 40
			nump 44
			size 0.515950935
			rea 0.228944225
			rpp 2.825
			rion 0.8
			rcov 145
			rmet 1.602
			tmelt 0.556282723
			eneg 3.14
		*
			name \Nb
			num 41
			nump 48
			size 0.494387183
			rea 0.238930116
			rpp 2.76
			rion 0.77
			rcov 134
			rmet 1.468
			tmelt 0.717539267
			eneg 3.25
		*
			name \Mo
			num 42
			nump 52
			size 0.473421463
			rea 0.249511262
			rpp 2.72
			rion 0.75
			rcov 129
			rmet 1.4
			tmelt 0.756544503
			eneg 3.41
		*
			name \Tc
			num 43
			nump 56
			size 0.452993466
			rea 0.260763114
			rpp 2.65
			rion 0.72
			rcov 0
			rmet 1.36
			tmelt 0.640052356
			eneg 3.35
		*
			name \Ru
			num 44
			nump 60
			size 0.433052136
			rea 0.272770822
			rpp 2.605
			rion 0.69
			rcov 124
			rmet 1.339
			tmelt 0.67617801
			eneg 3.47
		*
			name \Rh
			num 45
			nump 64
			size 0.413553912
			rea 0.28563141
			rpp 2.52
			rion 0.75
			rcov 125
			rmet 1.345
			tmelt 0.586125654
			eneg 3.57
		*
			name \Pd
			num 46
			nump 68
			size 0.394461351
			rea 0.299456427
			rpp 2.45
			rion 0.85
			rcov 128
			rmet 1.376
			tmelt 0.477748691
			eneg 3.73
		*
			name \Ag
			num 47
			nump 72
			size 0.375742041
			rea 0.31437522
			rpp 2.375
			rion 1.12
			rcov 134
			rmet 1.445
			tmelt 0.323036649
			eneg 3.81
		*
			name \Cd
			num 48
			nump 78
			size 0.347395886
			rea 0.340027017
			rpp 2.215
			rion 0.93
			rcov 141
			rmet 1.568
			tmelt 0.155497382
			eneg 3.6
		*
			name \In
			num 49
			nump 84
			size 0.319938571
			rea 0.369208334
			rpp 2.05
			rion 0.76
			rcov 150
			rmet 1.663
			tmelt 0.112565445
			eneg 4.19
		*
			name \Sn
			num 50
			nump 90
			size 0.293280312
			rea 0.402768213
			rpp 1.88
			rion 0.65
			rcov 140
			rmet 1.623
			tmelt 0.132198953
			eneg 4.83
		*
			name \Sb
			num 51
			nump 96
			size 0.267345197
			rea 0.441840692
			rpp 1.765
			rion 0.57
			rcov 141
			rmet 1.59
			tmelt 0.236649215
			eneg 5.47
		*
			name \Te
			num 52
			nump 102
			size 0.242068451
			rea 0.487977621
			rpp 1.67
			rion 0.51
			rcov 137
			rmet 1.6
			tmelt 0.189267016
			eneg 6.08
		*
			name \I
			num 53
			nump 109
			size 0.213172397
			rea 0.554124213
			rpp 1.585
			rion 0.46
			rcov 133
			rmet 0
			tmelt 0.101308901
			eneg 6.69
		*
			name \Xe
			num 54
			nump 116
			size 0.185071259
			rea 0.63826219
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.042146597
			eneg 7.29
		*
			name \Cs
			num 55
			nump 5
			size 0.910157427
			rea 0.129784127
			rpp 4.31
			rion 1.65
			rcov 235
			rmet 2.731
			tmelt 0.079057592
			eneg 1.97
		*
			name \Ba
			num 56
			nump 9
			size 0.868793456
			rea 0.135963256
			rpp 3.402
			rion 1.26
			rcov 198
			rmet 2.243
			tmelt 0.261256545
			eneg 2.76
		*
			name \La
			num 57
			nump 13
			size 0.828185801
			rea 0.142629814
			rpp 0
			rion 1.06
			rcov 169
			rmet 1.877
			tmelt 0.312303665
			eneg 2.89
		*
			name \Ce
			num 58
			nump 15
			size 0.810462652
			rea 0.145748835
			rpp 0
			rion 1.05
			rcov 165
			rmet 1.715
			tmelt 0.280366492
			eneg 2.86
		*
			name \Pr
			num 59
			nump 17
			size 0.793233638
			rea 0.148914495
			rpp 0
			rion 1.04
			rcov 165
			rmet 1.828
			tmelt 0.315183246
			eneg 2.83
		*
			name \Nd
			num 60
			nump 19
			size 0.776484764
			rea 0.1521266
			rpp 0
			rion 1.03
			rcov 164
			rmet 1.821
			tmelt 0.338743455
			eneg 2.85
		*
			name \Pm
			num 61
			nump 21
			size 0.760190637
			rea 0.155387322
			rpp 0
			rion 1.02
			rcov 0
			rmet 1.81
			tmelt 0.377225131
			eneg 2.87
		*
			name \Sm
			num 62
			nump 23
			size 0.744322073
			rea 0.158700099
			rpp 0
			rion 1.01
			rcov 166
			rmet 1.802
			tmelt 0.352094241
			eneg 2.89
		*
			name \Eu
			num 63
			nump 25
			size 0.728849599
			rea 0.162069084
			rpp 0
			rion 1.01
			rcov 185
			rmet 1.799
			tmelt 0.286649215
			eneg 2.91
		*
			name \Gd
			num 64
			nump 27
			size 0.713745039
			rea 0.165498855
			rpp 0
			rion 1
			rcov 161
			rmet 1.802
			tmelt 0.414921466
			eneg 3.02
		*
			name \Tb
			num 65
			nump 29
			size 0.698982175
			rea 0.168994276
			rpp 0
			rion 0.99
			rcov 159
			rmet 1.782
			tmelt 0.427486911
			eneg 2.95
		*
			name \Dy
			num 66
			nump 31
			size 0.684536953
			rea 0.172560424
			rpp 0
			rion 0.98
			rcov 159
			rmet 1.773
			tmelt 0.439790576
			eneg 2.97
		*
			name \Ho
			num 67
			nump 33
			size 0.670387461
			rea 0.17620256
			rpp 0
			rion 0.97
			rcov 158
			rmet 1.766
			tmelt 0.456282723
			eneg 2.99
		*
			name \Er
			num 68
			nump 35
			size 0.65651381
			rea 0.179926127
			rpp 0
			rion 0.96
			rcov 157
			rmet 1.757
			tmelt 0.469895288
			eneg 3
		*
			name \Tm
			num 69
			nump 37
			size 0.642897972
			rea 0.183736755
			rpp 0
			rion 0.95
			rcov 156
			rmet 1.746
			tmelt 0.47591623
			eneg 3.02
		*
			name \Yb
			num 70
			nump 39
			size 0.629523601
			rea 0.187640284
			rpp 0
			rion 0.94
			rcov 170
			rmet 1.74
			tmelt 0.287172775
			eneg 3.04
		*
			name \Lu
			num 71
			nump 41
			size 0.616375866
			rea 0.191642784
			rpp 0
			rion 0.93
			rcov 156
			rmet 1.734
			tmelt 0.504973822
			eneg 3.11
		*
			name \Hf
			num 72
			nump 45
			size 0.588840308
			rea 0.200604451
			rpp 2.91
			rion 0.8
			rcov 144
			rmet 1.58
			tmelt 0.653141361
			eneg 3.3
		*
			name \Ta
			num 73
			nump 49
			size 0.562314966
			rea 0.210067301
			rpp 2.79
			rion 0.77
			rcov 134
			rmet 1.467
			tmelt 0.856806283
			eneg 3.45
		*
			name \W
			num 74
			nump 53
			size 0.536696361
			rea 0.22009463
			rpp 2.735
			rion 0.75
			rcov 130
			rmet 1.408
			tmelt 0.964136126
			eneg 3.48
		*
			name \Re
			num 75
			nump 57
			size 0.51189659
			rea 0.230757519
			rpp 2.68
			rion 0.72
			rcov 128
			rmet 1.375
			tmelt 0.903926702
			eneg 3.5
		*
			name \Os
			num 76
			nump 61
			size 0.487840384
			rea 0.242136549
			rpp 2.65
			rion 0.69
			rcov 126
			rmet 1.353
			tmelt 0.868586387
			eneg 3.57
		*
			name \Ir
			num 77
			nump 65
			size 0.464462811
			rea 0.254323886
			rpp 2.628
			rion 0.81
			rcov 126
			rmet 1.357
			tmelt 0.702356021
			eneg 3.6
		*
			name \Pt
			num 78
			nump 69
			size 0.441707474
			rea 0.267425828
			rpp 2.7
			rion 0.9
			rcov 129
			rmet 1.387
			tmelt 0.535340314
			eneg 3.71
		*
			name \Au
			num 79
			nump 73
			size 0.419525064
			rea 0.281565982
			rpp 2.66
			rion 1.11
			rcov 134
			rmet 1.442
			tmelt 0.35
			eneg 3.84
		*
			name \Hg
			num 80
			nump 79
			size 0.386690726
			rea 0.305474062
			rpp 2.41
			rion 0.97
			rcov 144
			rmet 1.573
			tmelt 0.061256545
			eneg 3.82
		*
			name \Tl
			num 81
			nump 85
			size 0.355029594
			rea 0.332715889
			rpp 2.235
			rion 0.9
			rcov 155
			rmet 1.716
			tmelt 0.15104712
			eneg 4.34
		*
			name \Pb
			num 82
			nump 91
			size 0.324425963
			rea 0.364101522
			rpp 2.09
			rion 0.83
			rcov 154
			rmet 1.75
			tmelt 0.157329843
			eneg 4.92
		*
			name \Bi
			num 83
			nump 97
			size 0.294781292
			rea 0.400717379
			rpp 1.997
			rion 0.77
			rcov 152
			rmet 1.7
			tmelt 0.142408377
			eneg 5.47
		*
			name \Po
			num 84
			nump 103
			size 0.266010922
			rea 0.444056906
			rpp 1.9
			rion 0.56
			rcov 153
			rmet 1.76
			tmelt 0.137958115
			eneg 6.01
		*
			name \At
			num 85
			nump 110
			size 0.233351806
			rea 0.506205582
			rpp 1.83
			rion 0.51
			rcov 0
			rmet 0
			tmelt 0.15052356
			eneg 6.56
		*
			name \Rn
			num 86
			nump 117
			size 0.201712905
			rea 0.58560451
			rpp 0
			rion 0
			rcov 0
			rmet 0
			tmelt 0.052879581
			eneg 7.12
		*
			name \Fr
			num 87
			nump 6
			size 1
			rea 0.118123987
			rpp 4.37
			rion 1.74
			rcov 0
			rmet 2.8
			tmelt 0.078534031
			eneg 2.02
		*
			name \Ra
			num 88
			nump 10
			size 0.952025289
			rea 0.124076522
			rpp 3.53
			rion 1.34
			rcov 0
			rmet 2.26
			tmelt 0.254712042
			eneg 2.78
		*
			name \Ac
			num 89
			nump 14
			size 0.905996701
			rea 0.130380151
			rpp 0
			rion 1.14
			rcov 0
			rmet 1.878
			tmelt 0.346335079
			eneg 2.93
		*
			name \Th
			num 90
			nump 16
			size 0.885161237
			rea 0.133449119
			rpp 0
			rion 1.11
			rcov 0
			rmet 1.798
			tmelt 0.528795812
			eneg 3.02
		*
			name \Pa
			num 91
			nump 18
			size 0.864979518
			rea 0.136562756
			rpp 0
			rion 1.08
			rcov 0
			rmet 1.63
			tmelt 0.553141361
			eneg 2.98
		*
			name \U
			num 92
			nump 20
			size 0.845420273
			rea 0.139722208
			rpp 0
			rion 1.05
			rcov 0
			rmet 1.56
			tmelt 0.368062827
			eneg 2.98
		*
			name \Np
			num 93
			nump 22
			size 0.826445343
			rea 0.142930186
			rpp 0
			rion 1.04
			rcov 0
			rmet 1.555
			tmelt 0.239005236
			eneg 2.98
		*
			name \Pu
			num 94
			nump 24
			size 0.808015348
			rea 0.146190276
			rpp 0
			rion 1.03
			rcov 0
			rmet 1.58
			tmelt 0.239267016
			eneg 2.96
		*
			name \Am
			num 95
			nump 26
			size 0.790092251
			rea 0.149506576
			rpp 0
			rion 1.02
			rcov 0
			rmet 1.81
			tmelt 0.331675393
			eneg 2.97
