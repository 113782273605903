$mpds_visavis_plot_cube $mol_view
	plot_raw $mpds_visavis_plot_raw
	auto /
		<= subscribe_click null
	show_setup true
	show_fixel? true
	heatmap? false
	order* /number
	order_current? \nump
	x_op? false
	y_op? false
	z_op? false
	x_sort? \nump
	y_sort? \nump
	z_sort? \nump
	cube_click? null
	colorset /
		\#3e3f95
		\#c00
		\#FE9A2E
		\#090
		\#f0f
		\#09f
		\#666
		\#0f3
		\#0ff
		\#90c
	heatmap_colors /
		\rgb(150,0,90)
		\rgb(0,0,200)
		\rgb(0,25,255)
		\rgb(0,152,255)
		\rgb(44,255,150)
		\rgb(151,255,0)
		\rgb(255,234,0)
		\rgb(255,111,0)
		\rgb(255,0,0)
	sub /
		<= Plot $mol_view
			sub <= plot_body /
				<= Root $mpds_visavis_lib_plotly_view
					data <= data_shown /
					layout <= layout *
					plot_options *
						displaylogo false
						displayModeBar false
						staticPlot false
					Plotly_root => Plotly_root
				<= Cmp_legend $mpds_visavis_plot_legend_cmp
					labels <= cmp_labels /
					colorset <= colorset
				<= Side_right $mol_scroll
					sub /
						<= Heatmap_legend $mol_list
							rows <= heatmap_color_list /
								<= Heatmap_min $mol_view sub / <= value_min 0
								<= Heatmap_color*0 $mol_view
									style * background <= heatmap_color* \
								<= Heatmap_max $mol_view sub / <= value_max 0
		<= Setup $mol_view
			sub <= setup /
				<= Fixel $mol_check_box
					title \Project on 2d
					checked? <=> fixel_checked? false
				<= Nonformers $mol_check_box
					hint \Continuous solid solutions and complete insolubility systems
					title \Show non-formers
					checked? <=> nonformers_checked? false
				<= Intersection_on $mol_check_box
					title \Show intersection
					checked? <=> intersection_only? false
				<= X_order $mol_labeler
					title \X sort by
					Content <= X_order_select $mol_select
						value? <=> x_sort?
						dictionary <= sort_dict *
				<= Y_order $mol_labeler
					title \Y sort by
					Content <= Y_order_select $mol_select
						value? <=> y_sort?
						dictionary <= sort_dict
				<= Z_order $mol_labeler
					title \Z sort by
					Content <= Z_order_select $mol_select
						value? <=> z_sort?
						dictionary <= sort_dict
