$mpds_visavis_plot_graph $mol_view
	plot_raw $mpds_visavis_plot_raw
	notify? null
	plugins /
		<= Touch $mol_touch
			allow_draw false
			allow_pan <= allow_pan? true
			pan? => pan?
	sub /
		<= Root $mol_svg_root
			view_box <= view_box \0 0 0 0
			auto <= draw null
	graph_rel? \
	graph_click? null
