$mpds_visavis_plot_discovery $mol_view
	plot_raw $mpds_visavis_plot_raw
	auto /
		<= subscribe_click null
	json_cmp? null
	elementals_on? /
	show_setup true
	discovery_click? null
	colorset /
		\#3e3f95
		\#c00
	sub /
		<= Plot $mpds_visavis_lib_plotly_view
			Plotly_root => Plotly_root
			data <= data /
			layout <= layout *
		<= Cmp_legend $mpds_visavis_plot_legend_cmp
			labels <= cmp_labels /
			colorset <= colorset
		<= Setup $mol_view
			sub /
				<= Elementals $mol_labeler
					title \Select property
					Content <= Elementals_check $mol_check_list
						option_checked*? <=> elemental_checked*? false
						options <= elementals_dict *
