$mpds_visavis_plot $mol_view
	attr *
		^
		mol_theme \$mol_theme_light
		fullscreen <= fullscreen?
	Locale $mol_locale_select
		value <= locale \en
	json_request? null
	json_cmp_request? null
	multi_requests? /string
	jsons? /
	plot_raw null
	plot_raw_visible null
	show_setup false
	notify? null
	show_demo_warn? false
	reset null
	sub? /
		^ demo_warn_visible / <= Demo_warn $mol_paragraph
			title \You are using the limited demo. Buy the full access and support our work.
		<= Fullscreen $mol_check
			Icon <= Expand_icon $mol_icon_arrow_expand_all
			checked? <=> fullscreen? false
		<= Plot $mol_view
		^ error_visible / <= Error $mol_view
			sub / <= error_message \
	plots *
		matrix <= Matrix $mpds_visavis_plot_matrix
			plot_raw <= plot_raw_visible
			show_setup <= show_setup
			nonformers_checked? <=> nonformers_checked? false
			fixel_checked? <=> matrix_fixel_checked? true
			x_op? => matrix_x_op?
			y_op? => matrix_y_op?
			x_sort? => matrix_x_sort?
			y_sort? => matrix_y_sort?
			matrix_click? <=> matrix_click? null
		plot3d <= Cube $mpds_visavis_plot_cube
			plot_raw <= plot_raw_visible
			show_setup <= show_setup
			show_fixel? <= show_fixel? true
			nonformers_checked? <=> nonformers_checked?
			fixel_checked? <=> cube_fixel_checked? false
			x_op? => x_op?
			y_op? => y_op?
			z_op? => z_op?
			x_sort? => x_sort?
			y_sort? => y_sort?
			z_sort? => z_sort?
			cube_click? <=> cube_click? null
		pd <= Phase $mpds_visavis_plot_phase
			plot_raw <= plot_raw_visible
			phase_click? <=> phase_click? null
			data_demo => phase_data_demo
		bar <= Bar $mpds_visavis_plot_bar
			plot_raw <= plot_raw_visible
			bar_click? <=> bar_click? null
		discovery <= Discovery $mpds_visavis_plot_discovery
			plot_raw <= plot_raw_visible
			show_setup <= show_setup
			elementals_on? => discovery_elementals_on?
			discovery_click? <=> discovery_click? null
		eigenplot <= Eigen $mpds_visavis_plot_eigen
			plot_raw <= plot_raw_visible
		pie <= Pie $mpds_visavis_plot_pie
			plot_raw <= plot_raw_visible
			pie_click? <=> pie_click? null
		scatter <= Scatter $mpds_visavis_plot_scatter
			plot_raw <= plot_raw_visible
			notify? <=> notify?
		customscatter <= Customscatter $mpds_visavis_plot_customscatter
			plot_raw <= plot_raw_visible
			nplots_changed? <=> nplots_changed? null
			legend_click? <=> legend_click? null
		heatmap <= Heatmap $mpds_visavis_plot_heatmap
			plot_raw <= plot_raw_visible
		graph <= Graph $mpds_visavis_plot_graph
			plot_raw <= plot_raw_visible
			graph_rel? => graph_rel?
			graph_click? <=> graph_click? null
			notify? <=> notify?
