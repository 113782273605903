namespace $.$$ {

	$mol_style_define( $.$mpds_visavis_plot_graph, {

		Root: {
			width: '100%',
			height: '100%',
			userSelect: 'none',
		},

		flex: {
			grow: 1,
		},

	} )

}
