$mpds_visavis_plot_pie $mpds_visavis_lib_plotly_view
	plot_raw $mpds_visavis_plot_raw
	auto /
		<= subscribe_click null
	pie_click? null
	colorset /
		\#3e3f95
		\#c00
		\#FE9A2E
		\#090
		\#f0f
		\#09f
		\#666
		\#0f3
		\#0ff
		\#90c
