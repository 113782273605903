namespace $.$$ {

	$mol_style_define( $mpds_visavis_plot_phase, {

		Label: {
			position: 'absolute',
			top: '15px',
			left: 0,
			right: 0,
			justifyContent: 'center',
			zIndex: 2,
			background: 'none',
		}

	} )

}
