$mpds_visavis_elements_nonformer $mol_object
	pd_bin /
		/
			88
			23
		/
			88
			41
		/
			46
			78
		/
			46
			82
		/
			46
			5
		/
			46
			26
		/
			46
			23
		/
			46
			28
		/
			46
			25
		/
			46
			31
		/
			46
			76
		/
			46
			24
		/
			46
			41
		/
			46
			27
		/
			46
			75
		/
			46
			81
		/
			46
			74
		/
			46
			44
		/
			46
			43
		/
			46
			13
		/
			46
			80
		/
			46
			91
		/
			46
			22
		/
			12
			3
		/
			12
			82
		/
			12
			47
		/
			12
			54
		/
			12
			30
		/
			12
			31
		/
			12
			79
		/
			12
			48
		/
			12
			18
		/
			12
			10
		/
			12
			81
		/
			12
			13
		/
			12
			49
		/
			12
			80
		/
			12
			29
		/
			94
			23
		/
			94
			41
		/
			94
			92
		/
			94
			93
		/
			94
			91
		/
			17
			6
		/
			32
			78
		/
			32
			82
		/
			32
			81
		/
			32
			80
		/
			78
			5
		/
			78
			26
		/
			78
			25
		/
			78
			31
		/
			78
			41
		/
			78
			27
		/
			78
			14
		/
			78
			77
		/
			78
			44
		/
			78
			43
		/
			78
			13
		/
			78
			73
		/
			55
			19
		/
			55
			62
		/
			55
			25
		/
			55
			63
		/
			55
			56
		/
			55
			24
		/
			55
			41
		/
			55
			59
		/
			55
			27
		/
			55
			60
		/
			55
			58
		/
			55
			20
		/
			55
			61
		/
			55
			37
		/
			55
			21
		/
			55
			22
		/
			55
			38
		/
			55
			69
		/
			4
			82
		/
			4
			47
		/
			4
			28
		/
			3
			82
		/
			3
			30
		/
			3
			31
		/
			3
			48
		/
			3
			2
		/
			3
			10
		/
			3
			13
		/
			3
			49
		/
			3
			29
		/
			4
			30
		/
			4
			31
		/
			4
			79
		/
			82
			47
		/
			82
			26
		/
			82
			23
		/
			82
			28
		/
			82
			25
		/
			82
			30
		/
			82
			31
		/
			82
			79
		/
			82
			41
		/
			82
			40
		/
			82
			75
		/
			82
			14
		/
			82
			74
		/
			82
			50
		/
			82
			13
		/
			82
			49
		/
			82
			72
		/
			82
			22
		/
			82
			29
		/
			4
			81
		/
			4
			50
		/
			4
			49
		/
			4
			51
		/
			4
			80
		/
			4
			29
		/
			19
			57
		/
			19
			26
		/
			19
			62
		/
			19
			25
		/
			19
			56
		/
			19
			70
		/
			19
			24
		/
			19
			41
		/
			19
			10
		/
			19
			59
		/
			19
			60
		/
			19
			93
		/
			19
			20
		/
			19
			37
		/
			19
			64
		/
			19
			21
		/
			19
			68
		/
			19
			22
		/
			19
			38
		/
			19
			69
		/
			47
			23
		/
			47
			25
		/
			47
			30
		/
			47
			31
		/
			47
			24
		/
			47
			41
		/
			47
			81
		/
			47
			13
		/
			47
			80
		/
			47
			29
		/
			57
			23
		/
			57
			62
		/
			57
			71
		/
			57
			56
		/
			57
			2
		/
			57
			24
		/
			57
			41
		/
			57
			40
		/
			57
			59
		/
			57
			60
		/
			57
			58
		/
			57
			93
		/
			57
			20
		/
			57
			61
		/
			57
			72
		/
			57
			89
		/
			57
			21
		/
			57
			91
		/
			57
			22
		/
			57
			73
		/
			57
			39
		/
			5
			25
		/
			5
			26
		/
			5
			28
		/
			5
			31
		/
			5
			76
		/
			5
			27
		/
			5
			7
		/
			5
			43
		/
			26
			28
		/
			26
			25
		/
			26
			79
		/
			26
			24
		/
			26
			27
		/
			26
			75
		/
			26
			81
		/
			26
			45
		/
			26
			77
		/
			26
			74
		/
			26
			44
		/
			26
			43
		/
			5
			45
		/
			5
			77
		/
			23
			54
		/
			23
			28
		/
			23
			65
		/
			23
			67
		/
			23
			62
		/
			23
			63
		/
			5
			44
		/
			23
			79
		/
			23
			66
		/
			23
			18
		/
			23
			56
		/
			23
			2
		/
			23
			70
		/
			23
			11
		/
			23
			41
		/
			23
			10
		/
			23
			59
		/
			23
			92
		/
			23
			81
		/
			23
			60
		/
			23
			58
		/
			23
			93
		/
			23
			36
		/
			23
			20
		/
			23
			61
		/
			23
			64
		/
			23
			89
		/
			23
			68
		/
			23
			91
		/
			23
			22
		/
			23
			73
		/
			23
			38
		/
			23
			69
		/
			54
			71
		/
			54
			2
		/
			54
			11
		/
			54
			41
		/
			54
			40
		/
			54
			58
		/
			54
			36
		/
			54
			72
		/
			54
			21
		/
			54
			22
		/
			54
			39
		/
			5
			42
		/
			28
			25
		/
			28
			76
		/
			28
			2
		/
			28
			41
		/
			28
			10
		/
			28
			40
		/
			28
			27
		/
			28
			75
		/
			28
			81
		/
			28
			74
		/
			28
			44
		/
			28
			43
		/
			28
			72
		/
			28
			42
		/
			28
			80
		/
			28
			22
		/
			28
			73
		/
			65
			67
		/
			65
			63
		/
			65
			66
		/
			65
			70
		/
			65
			41
		/
			65
			93
		/
			65
			61
		/
			65
			72
		/
			65
			64
		/
			65
			89
		/
			65
			21
		/
			65
			68
		/
			65
			22
		/
			65
			73
		/
			65
			38
		/
			65
			69
		/
			65
			39
		/
			67
			63
		/
			67
			71
		/
			67
			66
		/
			67
			2
		/
			67
			70
		/
			67
			41
		/
			67
			40
		/
			67
			93
		/
			67
			20
		/
			67
			61
		/
			67
			72
		/
			67
			64
		/
			67
			89
		/
			67
			21
		/
			67
			68
		/
			67
			22
		/
			67
			73
		/
			67
			38
		/
			67
			69
		/
			67
			39
		/
			62
			71
		/
			62
			56
		/
			62
			24
		/
			62
			41
		/
			62
			40
		/
			62
			93
		/
			62
			20
		/
			62
			72
		/
			62
			89
		/
			62
			91
		/
			62
			22
		/
			62
			73
		/
			62
			38
		/
			62
			69
		/
			62
			39
		/
			25
			0
		/
			25
			48
		/
			25
			18
		/
			25
			56
		/
			25
			2
		/
			25
			11
		/
			25
			10
		/
			25
			75
		/
			25
			81
		/
			25
			43
		/
			25
			37
		/
			86
			41
		/
			30
			31
		/
			30
			79
		/
			30
			48
		/
			30
			81
		/
			30
			74
		/
			30
			13
		/
			30
			49
		/
			30
			80
		/
			30
			29
		/
			63
			66
		/
			63
			2
		/
			63
			70
		/
			63
			41
		/
			63
			93
		/
			63
			20
		/
			63
			61
		/
			63
			72
		/
			63
			64
		/
			63
			89
		/
			63
			21
		/
			63
			68
		/
			63
			22
		/
			63
			73
		/
			63
			38
		/
			63
			69
		/
			63
			39
		/
			31
			79
		/
			31
			48
		/
			31
			81
		/
			31
			13
		/
			31
			49
		/
			31
			80
		/
			31
			29
		/
			71
			18
		/
			71
			2
		/
			71
			11
		/
			71
			10
		/
			71
			40
		/
			71
			58
		/
			71
			36
		/
			71
			20
		/
			71
			72
		/
			71
			89
		/
			71
			21
		/
			71
			91
		/
			71
			38
		/
			71
			69
		/
			71
			39
		/
			0
			41
		/
			79
			76
		/
			79
			41
		/
			79
			40
		/
			79
			75
		/
			79
			81
		/
			79
			74
		/
			79
			43
		/
			79
			50
		/
			79
			22
		/
			66
			2
		/
			66
			70
		/
			66
			41
		/
			66
			93
		/
			66
			20
		/
			66
			61
		/
			66
			72
		/
			66
			64
		/
			66
			89
		/
			66
			68
		/
			66
			22
		/
			66
			73
		/
			66
			38
		/
			66
			69
		/
			66
			39
		/
			0
			29
		/
			48
			41
		/
			48
			13
		/
			48
			72
		/
			48
			22
		/
			48
			29
		/
			76
			75
		/
			76
			45
		/
			76
			77
		/
			76
			74
		/
			76
			44
		/
			76
			43
		/
			76
			42
		/
			52
			15
		/
			52
			33
		/
			18
			2
		/
			18
			11
		/
			18
			41
		/
			18
			40
		/
			18
			36
		/
			18
			72
		/
			18
			21
		/
			18
			22
		/
			18
			39
		/
			56
			2
		/
			56
			70
		/
			56
			24
		/
			56
			41
		/
			56
			40
		/
			56
			59
		/
			56
			60
		/
			56
			58
		/
			56
			93
		/
			56
			74
		/
			56
			20
		/
			56
			61
		/
			56
			72
		/
			56
			89
		/
			56
			21
		/
			56
			91
		/
			56
			22
		/
			56
			73
		/
			56
			69
		/
			56
			39
		/
			2
			70
		/
			2
			11
		/
			2
			24
		/
			2
			41
		/
			2
			10
		/
			2
			40
		/
			2
			59
		/
			2
			27
		/
			2
			60
		/
			2
			58
		/
			2
			93
		/
			2
			36
		/
			2
			20
		/
			2
			61
		/
			2
			72
		/
			2
			64
		/
			2
			21
		/
			2
			68
		/
			2
			22
		/
			2
			38
		/
			2
			39
		/
			70
			41
		/
			70
			40
		/
			70
			59
		/
			70
			93
		/
			70
			61
		/
			70
			72
		/
			70
			64
		/
			70
			89
		/
			70
			21
		/
			70
			68
		/
			70
			22
		/
			70
			73
		/
			70
			38
		/
			70
			69
		/
			70
			39
		/
			11
			24
		/
			11
			41
		/
			11
			10
		/
			11
			40
		/
			11
			36
		/
			11
			21
		/
			11
			91
		/
			11
			22
		/
			11
			39
		/
			24
			81
		/
			24
			43
		/
			24
			37
		/
			24
			80
		/
			24
			69
		/
			41
			10
		/
			41
			40
		/
			41
			59
		/
			41
			92
		/
			41
			90
		/
			41
			81
		/
			41
			60
		/
			41
			58
		/
			41
			93
		/
			41
			87
		/
			41
			36
		/
			41
			20
		/
			41
			61
		/
			41
			37
		/
			41
			72
		/
			41
			64
		/
			41
			42
		/
			41
			89
		/
			41
			21
		/
			41
			80
		/
			41
			68
		/
			41
			22
		/
			41
			73
		/
			41
			38
		/
			41
			69
		/
			10
			40
		/
			10
			36
		/
			10
			37
		/
			10
			72
		/
			10
			21
		/
			10
			22
		/
			10
			39
		/
			40
			93
		/
			40
			36
		/
			40
			20
		/
			40
			61
		/
			40
			72
		/
			40
			89
		/
			40
			21
		/
			40
			80
		/
			40
			91
		/
			40
			22
		/
			40
			73
		/
			40
			38
		/
			40
			69
		/
			40
			39
		/
			59
			60
		/
			59
			58
		/
			59
			93
		/
			59
			20
		/
			59
			61
		/
			59
			37
		/
			59
			72
		/
			59
			89
		/
			59
			21
		/
			59
			91
		/
			59
			22
		/
			59
			73
		/
			59
			69
		/
			59
			39
		/
			92
			93
		/
			27
			75
		/
			27
			81
		/
			27
			45
		/
			27
			74
		/
			27
			44
		/
			27
			43
		/
			27
			42
		/
			27
			80
		/
			75
			45
		/
			75
			77
		/
			75
			74
		/
			75
			44
		/
			75
			43
		/
			81
			50
		/
			81
			13
		/
			81
			49
		/
			81
			80
		/
			81
			73
		/
			81
			29
		/
			45
			77
		/
			45
			74
		/
			45
			44
		/
			45
			43
		/
			45
			42
		/
			45
			73
		/
			60
			58
		/
			60
			93
		/
			60
			61
		/
			60
			89
		/
			60
			22
		/
			58
			93
		/
			58
			61
		/
			58
			72
		/
			58
			89
		/
			58
			21
		/
			58
			91
		/
			58
			22
		/
			58
			73
		/
			58
			69
		/
			14
			50
		/
			77
			43
		/
			77
			42
		/
			93
			61
		/
			93
			72
		/
			93
			64
		/
			93
			21
		/
			93
			68
		/
			93
			22
		/
			93
			73
		/
			93
			38
		/
			93
			69
		/
			36
			72
		/
			36
			21
		/
			36
			22
		/
			36
			39
		/
			74
			44
		/
			74
			43
		/
			74
			50
		/
			74
			49
		/
			74
			42
		/
			44
			43
		/
			44
			42
		/
			50
			13
		/
			50
			73
		/
			20
			37
		/
			20
			72
		/
			20
			64
		/
			20
			89
		/
			20
			21
		/
			20
			91
		/
			20
			22
		/
			20
			73
		/
			20
			38
		/
			20
			69
		/
			20
			39
		/
			33
			51
		/
			13
			49
		/
			13
			80
		/
			13
			29
		/
			61
			72
		/
			61
			64
		/
			61
			89
		/
			61
			68
		/
			61
			91
		/
			61
			22
		/
			61
			73
		/
			61
			38
		/
			49
			29
		/
			37
			21
		/
			37
			22
		/
			37
			38
		/
			15
			51
		/
			72
			64
		/
			72
			89
		/
			72
			21
		/
			72
			80
		/
			72
			68
		/
			72
			91
		/
			72
			38
		/
			72
			69
		/
			72
			39
		/
			64
			89
		/
			64
			21
		/
			64
			68
		/
			64
			22
		/
			64
			73
		/
			64
			38
		/
			64
			69
		/
			64
			39
		/
			89
			21
		/
			89
			68
		/
			89
			91
		/
			89
			22
		/
			89
			73
		/
			89
			38
		/
			89
			69
		/
			89
			39
		/
			21
			68
		/
			21
			22
		/
			21
			73
		/
			21
			38
		/
			21
			69
		/
			21
			39
		/
			80
			22
		/
			80
			29
		/
			68
			22
		/
			68
			73
		/
			68
			38
		/
			68
			69
		/
			91
			22
		/
			91
			73
		/
			91
			38
		/
			91
			69
		/
			22
			73
		/
			22
			38
		/
			22
			69
		/
			73
			38
		/
			73
			69
		/
			38
			69
		/
			38
			39
		/
			69
			39
		/
			46
			45
		/
			78
			23
		/
			78
			28
		/
			78
			24
		/
			78
			45
		/
			47
			11
		/
			57
			67
		/
			57
			63
		/
			57
			66
		/
			57
			70
		/
			57
			64
		/
			57
			68
		/
			57
			38
		/
			26
			25
		/
			26
			76
		/
			26
			77
		/
			23
			25
		/
			23
			27
		/
			23
			21
		/
			54
			18
		/
			28
			24
		/
			28
			45
		/
			28
			77
		/
			65
			56
		/
			65
			59
		/
			65
			60
		/
			65
			58
		/
			67
			56
		/
			67
			59
		/
			67
			60
		/
			67
			58
		/
			25
			76
		/
			25
			24
		/
			25
			27
		/
			25
			45
		/
			25
			44
		/
			25
			22
		/
			63
			56
		/
			63
			59
		/
			63
			60
		/
			63
			58
		/
			66
			56
		/
			66
			59
		/
			66
			60
		/
			66
			58
		/
			48
			80
		/
			76
			27
		/
			56
			64
		/
			56
			68
		/
			56
			38
		/
			70
			60
		/
			70
			58
		/
			24
			27
		/
			24
			22
		/
			59
			64
		/
			59
			68
		/
			59
			38
		/
			27
			77
		/
			45
			22
		/
			60
			64
		/
			60
			68
		/
			60
			38
		/
			58
			64
		/
			58
			68
		/
			58
			38
		/
			77
			74
		/
			77
			44
		/
			77
			73
		/
			93
			39
		/
			43
			22
		/
			72
			22
		/
			72
			73
		/
			21
			91
	pd_tri /string
		\Pm-Dy-Y
		\Yb-Gd-V
		\Cr-Ni-Pb
		\Pu-V-Mo
		\Pm-Er-V
		\Tm-Lu-W
		\Nd-Pu-V
		\Al-Zn-In
		\Eu-U-Ta
		\Ni-Ru-Au
		\Ce-Pm-Nd
		\Cu-Tc-Pd
		\La-Sm-Ho
		\La-Yb-Ta
		\Gd-Lu-Zr
		\Li-Hf-Ti
		\La-Dy-Ho
		\Ba-Yb-Sc
		\K-Zr-Nb
		\Y-Zr-Nb
		\Li-Ce-Tm
		\Sc-U-Cr
		\Ho-Er-Mo
		\Tm-V-Mo
		\La-Pu-Zr
		\Ir-Pd-Rh
		\Gd-Ho-Zr
		\Ca-Y-Tm
		\Rb-Hf-Zr
		\La-Yb-Sc
		\Nd-Pu-Zr
		\Yb-Y-Lu
		\La-V-Mo
		\Ba-La-Y
		\Cs-Li-Ta
		\Ba-Sr-Fe
		\Yb-Y-W
		\Cs-Pr-Ti
		\Zn-Bi-B
		\Tb-Pm-Y
		\Gd-Ta-V
		\Ce-Eu-Zr
		\Fe-Os-Rh
		\Nb-V-Mo
		\Eu-Hf-Zr
		\Be-Al-In
		\Pr-V-W
		\Li-Er-Lu
		\Pu-Ti-Cr
		\Rb-Na-V
		\Mg-V-Mo
		\Pr-Nd-Ho
		\Pr-Sm-Cr
		\Pd-Ru-C
		\Er-Pu-Mo
		\Ce-Zr-Ti
		\Li-Tm-Ta
		\Nd-Gd-Sc
		\Fe-Rh-Au
		\Cs-V-Cr
		\Ru-Pt-Au
		\Ca-Lu-Pu
		\Cs-K-Ta
		\Lu-Ta-Ti
		\Nd-U-W
		\Ga-Si-Ge
		\K-Mg-V
		\Ce-Th-Ta
		\Yb-Dy-Zr
		\Ba-Nd-Ti
		\Y-Pu-V
		\Pr-Gd-Lu
		\La-Y-Zr
		\Er-Nb-V
		\Cs-Zr-Ta
		\Li-Y-Mo
		\Pr-Th-Mo
		\Fe-Cu-Pd
		\Ce-Sm-Y
		\Dy-Y-Ho
		\Cu-Ir-Rh
		\Si-Sb-Pb
		\Pr-Ho-Pu
		\K-Li-Cr
		\La-Tb-Dy
		\Li-Nd-Mo
		\Sm-Th-Cr
		\Cs-Zr-Nb
		\Pr-Y-Pu
		\Tb-Yb-Tm
		\Y-Hf-Nb
		\Na-Cr-Mo
		\K-Mg-Nb
		\Li-Er-Tm
		\Ce-Hf-Th
		\Dy-Ho-Lu
		\Ce-Mn-V
		\Mn-Fe-Pb
		\Rb-Hf-Mg
		\La-Tb-V
		\Sr-Nd-Sc
		\Tb-Sm-Cr
		\Ce-Sc-U
		\Y-Ho-V
		\Rb-Li-V
		\Nd-Th-Sc
		\Al-Si-Ge
		\Ca-La-Tm
		\La-Sc-Nb
		\Sm-Gd-Mo
		\Y-Sc-Ti
		\Na-Sr-Fe
		\K-Li-Nb
		\Cs-Rb-Hf
		\Zn-Ga-Bi
		\La-Pm-Mo
		\Mn-Fe-Co
		\Gd-Tm-Cr
		\Sm-Gd-Ho
		\U-Ti-W
		\La-Ce-Nb
		\Re-Hg-Sb
		\Gd-Dy-Pu
		\Dy-Ho-V
		\La-Nd-U
		\Ce-Ta-Mo
		\Ce-Lu-Ti
		\Ce-Nd-Ta
		\Pm-Tm-Cr
		\Ce-Tm-Pu
		\Pr-Sm-Th
		\Ca-La-Lu
		\Yb-Lu-Nb
		\Sm-Lu-V
		\Gd-Tm-Mo
		\Li-Pr-Pu
		\Al-Ga-Hg
		\Na-Ti-V
		\Yb-Nd-Gd
		\Nd-Gd-Mo
		\La-Tb-Pm
		\Ir-Pd-C
		\Tm-Pu-Mo
		\Tm-Th-V
		\La-Er-Zr
		\Cu-Ni-Ir
		\Tb-Sc-W
		\Ba-Sr-Mo
		\Th-Sc-V
		\Mn-Co-Ni
		\Cu-Ni-Ru
		\Tb-Ce-Ho
		\Nd-Lu-Mo
		\Li-Pm-Er
		\La-Tm-Lu
		\La-Pu-Mo
		\Pm-Pr-Lu
		\Ce-Ho-Pu
		\Cu-Tc-Pt
		\Nd-Dy-Tm
		\Ca-Pm-Mo
		\Fe-Cu-Au
		\Li-Sm-Gd
		\Mn-Ag-Pb
		\Li-Er-Ta
		\La-Tb-Ti
		\Gd-Ti-Cr
		\Ce-Gd-Th
		\Er-Sc-Cr
		\Sm-Er-Ta
		\Cr-Ni-Au
		\Nd-U-Ti
		\Co-Au-C
		\Nd-Pu-W
		\La-Yb-Th
		\Sc-Cr-Mo
		\Zr-Sc-Nb
		\Pr-Tm-Ti
		\Cs-Hf-Ti
		\Ce-Ho-Th
		\Yb-Gd-Tm
		\Li-Ce-Ta
		\Y-V-W
		\La-Sc-Ti
		\Tb-Sc-Ti
		\Tb-Pr-Ta
		\Sb-Pb-W
		\Ba-Yb-Gd
		\Dy-Pu-Cr
		\Tb-Gd-Tm
		\Tb-Lu-W
		\Gd-Er-V
		\Yb-Ti-W
		\Tm-V-W
		\Pd-Pt-Rh
		\Eu-Pu-Nb
		\Ba-Sm-Y
		\La-Sm-Y
		\U-V-Cr
		\Ba-Pm-Sm
		\Sm-Ho-Mo
		\Gd-Th-Ta
		\Ce-Pr-Cr
		\Lu-Ti-Mo
		\Dy-Lu-Th
		\La-Sc-Mo
		\Y-U-Cr
		\Pm-Nd-Sm
		\Tb-Gd-Mo
		\Yb-Er-Th
		\Li-Pr-Lu
		\Mg-Mn-Cd
		\Ce-Pm-Gd
		\Pu-Ti-Nb
		\Tl-Cd-Mo
		\Sc-V-Mo
		\Pu-V-Cr
		\Mn-Fe-Ni
		\Eu-Zr-Nb
		\Tb-Y-W
		\Tb-Pr-Y
		\Ca-Nd-Sc
		\Ba-Nd-Y
		\Ca-Tb-Sc
		\Pu-Ta-Ti
		\Ca-Yb-Eu
		\Th-Mo-W
		\Si-Sn-Bi
		\Tb-Sc-Mo
		\Na-Cr-Cu
		\Y-Er-W
		\Y-Ho-Mo
		\Ni-Pd-Rh
		\Y-Ti-Nb
		\Co-Pd-C
		\Li-Gd-Ti
		\Tb-Y-Er
		\Pr-Y-Cr
		\La-Yb-V
		\Ca-Yb-Mo
		\Dy-Er-Ti
		\Nd-Sm-Gd
		\Tb-Y-Ti
		\Gd-Er-Tm
		\Ca-Pm-Tm
		\Tl-Zn-Ge
		\Yb-Sc-Ta
		\Fe-Co-Au
		\La-Sm-Nb
		\Nb-V-Cu
		\La-Sm-V
		\Li-Pr-Mo
		\Tb-Gd-Ho
		\Eu-Th-Ta
		\Yb-U-Nb
		\Yb-Er-Hf
		\La-Ho-Zr
		\Dy-Y-Er
		\La-Ce-Tm
		\Pr-Lu-Mo
		\Eu-Y-Mo
		\Dy-Er-Cr
		\La-Yb-Ti
		\Ce-Pm-Mo
		\Tb-Pm-Pu
		\Pr-Dy-Y
		\La-Pu-Ti
		\Li-Ce-Sm
		\Li-Pr-Tm
		\Tb-Pm-Ho
		\Dy-Er-Lu
		\Al-In-Ga
		\Nd-Er-Ta
		\Pr-Nd-Y
		\Ba-Pm-V
		\Pr-Dy-V
		\Rb-Na-Ti
		\Tb-Pm-Gd
		\Gd-Er-Mo
		\Y-Th-Mo
		\Gd-Y-Mo
		\Ba-Pm-Mo
		\Pu-Nb-V
		\Er-Th-Mo
		\Co-Ni-Pb
		\Ho-Th-Zr
		\Pm-Pr-Gd
		\Sm-Lu-Mo
		\Yb-Pr-V
		\Cu-Ag-Pb
		\Pm-Ho-V
		\Re-Ir-Pd
		\Er-Lu-Nb
		\La-Eu-U
		\Cu-Ag-Mo
		\Dy-V-Cr
		\Dy-Th-Ti
		\Re-Bi-Sb
		\Pm-Pr-Y
		\Th-Sc-Ta
		\Y-V-Cr
		\Fe-Ag-Ir
		\Co-Os-Pd
		\Rb-Li-Zr
		\Gd-Dy-V
		\Tb-Er-Pu
		\Ce-Ti-Cr
		\Ba-Ti-V
		\Nd-Dy-Mo
		\Ca-Tm-Ti
		\Li-Hf-Mg
		\Tb-Sc-V
		\Tl-Zn-Pb
		\Pr-Tm-Cr
		\Er-Ti-Cr
		\Er-Sc-V
		\K-Li-Ta
		\Yb-Sc-U
		\La-Ce-Pu
		\Cs-Mg-Zr
		\Ga-B-Pb
		\Re-Ir-Ru
		\Pr-Gd-Er
		\Re-Ni-Pt
		\Tb-Nd-Tm
		\Tb-Ta-V
		\Nd-Y-Sc
		\Tb-Dy-Y
		\Tb-Pr-Cr
		\La-Zr-Ta
		\Eu-Th-U
		\Li-Tb-Ta
		\Na-V-Fe
		\Cu-Re-Pt
		\Cd-Ge-Bi
		\Sm-Gd-Ta
		\Al-In-Ge
		\Al-Cd-Ga
		\Ce-Eu-Y
		\La-Tb-Tm
		\Li-Tb-Zr
		\Pr-V-Cr
		\La-Pm-Ho
		\Gd-Er-Th
		\Li-La-V
		\Al-Ga-Sn
		\Tl-As-Pb
		\Re-Pd-Ru
		\Eu-Y-W
		\Tm-Ta-V
		\Eu-V-W
		\Be-Zn-Ge
		\Li-Pm-Gd
		\Na-Mg-Zr
		\Fe-Ag-Rh
		\Tl-Zn-Cd
		\Nd-Sm-Ho
		\Y-Ta-Mo
		\Gd-Lu-Ta
		\Li-Tb-Gd
		\Be-Ga-Si
		\Mn-Cu-Ru
		\Pr-Cr-W
		\Ho-Cr-W
		\Na-Li-Mg
		\Re-Ru-Pt
		\La-Tb-Ce
		\Cr-Pb-W
		\Pr-Er-Lu
		\Ag-Pd-Ru
		\Ca-Nd-Lu
		\Mg-Cr-Cd
		\Mn-Ni-Ag
		\Cu-B-Pb
		\Li-Er-V
		\Ni-Os-Rh
		\Fe-Ag-Pd
		\Li-Er-Pu
		\Sm-Y-U
		\Dy-Tm-Cr
		\Na-Nb-Mo
		\Tb-Zr-Ta
		\Rb-Na-Ta
		\Gd-Y-Er
		\Pr-Er-Tm
		\Pr-Sm-Ho
		\Gd-Tm-V
		\Zr-Ta-Nb
		\Cr-Hg-Bi
		\Yb-Er-Sc
		\Ca-Pm-Pu
		\Co-Cu-Ni
		\Gd-Ho-Lu
		\Sc-Ti-Cr
		\Ga-Si-Bi
		\Li-Lu-Mo
		\Ba-Sr-Mn
		\Li-Pm-Sm
		\Na-Li-Zr
		\Gd-Sc-V
		\Yb-Er-Mo
		\Ho-Lu-Zr
		\Nd-Sc-Cr
		\Ca-Ce-Ti
		\Pm-Gd-Ho
		\La-Y-Cr
		\Ca-Yb-Y
		\Pr-Th-U
		\Tm-Cr-Mo
		\Rb-Li-Ta
		\Gd-Y-Tm
		\Yb-Pr-U
		\Ce-Hf-Ta
		\Nd-Y-Ho
		\Er-Ti-Mo
		\Dy-Th-Mo
		\La-Pr-W
		\Yb-Ho-Lu
		\La-Yb-Nb
		\Co-Rh-C
		\La-Eu-Ta
		\Y-Cr-W
		\K-Zr-Ti
		\Pr-Sm-U
		\Eu-Sc-Cr
		\Ga-Sn-B
		\Cu-Ag-Rh
		\Ce-Gd-Zr
		\Tl-Ga-B
		\Gd-Er-Zr
		\Ti-Nb-Mo
		\Ga-Sn-Pb
		\La-Eu-Mn
		\Tm-Lu-Mo
		\Pm-Tm-Pu
		\Be-Al-Sn
		\Dy-Lu-Ti
		\Yb-Tm-Cr
		\Na-Ta-Nb
		\Li-Gd-Y
		\Na-Sr-Mo
		\Ba-La-Ti
		\Sm-Mo-W
		\La-Tm-Th
		\K-Cr-Fe
		\Y-Ta-Nb
		\Ce-Pm-Y
		\Sm-Tm-Lu
		\Er-Th-Nb
		\Co-Os-Rh
		\Sm-Dy-Lu
		\Sr-Ca-Y
		\Pm-Er-Th
		\Yb-V-W
		\La-Gd-W
		\Tb-Ta-Ti
		\Cs-Mg-Cr
		\Ca-Nd-Y
		\Pr-Ta-Mo
		\Pm-Sm-Th
		\La-Sm-Th
		\Zr-Ti-Nb
		\Cr-Cu-W
		\Cu-Ni-Pb
		\Sm-Pu-Cr
		\Ce-Pr-Er
		\Ce-Lu-Pu
		\La-V-Fe
		\Sm-Y-Ho
		\Ce-Er-Pu
		\Ce-Y-Hf
		\Ca-Pm-Nd
		\Ce-Pr-Ho
		\Ce-Lu-W
		\Re-Ru-Rh
		\Cu-Os-Pt
		\Co-Ni-Ir
		\La-Ta-Ti
		\Ba-V-Fe
		\Gd-Th-W
		\Li-Er-Nb
		\La-Yb-Er
		\Ce-Y-Ta
		\Y-Lu-Ti
		\Tm-Th-Mo
		\La-Eu-Zr
		\Pr-Dy-Mo
		\Ca-Yb-Mn
		\La-U-Ta
		\Pr-U-W
		\Nd-Lu-Ti
		\Pu-V-W
		\Li-Nd-Gd
		\Li-Ce-Hf
		\Tl-Cu-Mo
		\Al-Zn-Bi
		\Er-Sc-Ti
		\K-Mg-Ti
		\Li-Ce-Pu
		\Na-Nb-V
		\Ba-La-Pm
		\Tb-Th-Ta
		\Pr-Ho-Lu
		\Tb-Yb-Ti
		\Sm-Y-W
		\Tb-Dy-Zr
		\Ag-Ge-Au
		\Nd-Er-Cr
		\Yb-Y-Zr
		\Dy-Ho-Cr
		\Gd-Ta-W
		\Nd-Sm-Mo
		\Tb-Sm-Ta
		\Li-Lu-Cr
		\Y-Hf-Zr
		\Eu-Sc-U
		\Ti-Cr-W
		\Re-Ag-Bi
		\Pm-Er-Mo
		\Ca-Tb-Tm
		\Ta-Mo-W
		\Tb-Er-Cr
		\Li-La-Tb
		\Tl-Ag-Mo
		\Tb-Y-Mo
		\Er-Zr-Sc
		\Fe-Ni-Ag
		\La-Er-Tm
		\Co-Re-Ir
		\Li-Cr-Ni
		\V-Ag-Pd
		\Zn-Sn-Pb
		\Ce-Nd-W
		\La-Cr-W
		\Ca-Eu-Sc
		\Ho-Er-Cr
		\Yb-Zr-Nb
		\Eu-V-Mo
		\Ni-Ir-C
		\Y-Tm-Cr
		\Pr-Pu-Cr
		\Ho-Th-Ta
		\Pr-Tm-Lu
		\Pr-Er-Ti
		\Pr-Pu-Ta
		\Tb-Ce-Pm
		\Ce-Mo-W
		\Li-Tb-Pu
		\Mn-Tl-Ag
		\Ba-La-Sc
		\Th-Cr-W
		\Li-La-Cr
		\Mn-Fe-Cu
		\Ho-Tm-Pu
		\K-Rb-V
		\La-Gd-Tm
		\Cu-Ag-Ru
		\Ce-Y-Sc
		\Ho-Zr-Sc
		\Ce-Tm-Lu
		\Co-Ru-C
		\Ce-Ti-V
		\Yb-Hf-Sc
		\Tl-Si-Pb
		\Tb-Yb-Nd
		\Rb-Li-Cr
		\Co-Ni-C
		\Co-Ir-Ru
		\Gd-Lu-Pu
		\Gd-Dy-Zr
		\Mn-Cu-Ni
		\Cu-Mo-Au
		\Tb-Pr-Tm
		\Li-Mg-V
		\Yb-Pr-Y
		\Li-Sm-Mo
		\Li-Tb-Y
		\Sm-Nb-Mo
		\Ba-La-Yb
		\La-Dy-W
		\Zn-Si-Sn
		\Ca-Y-Pu
		\U-Ta-Ti
		\Pr-Lu-V
		\Si-Bi-Sb
		\Li-Ce-V
		\Cu-Re-Bi
		\Ba-Ca-Sc
		\La-Lu-Nb
		\Zn-Cd-B
		\In-Ga-Si
		\Dy-Er-Th
		\Cr-Hg-Pb
		\Yb-Dy-Y
		\Eu-Y-Nb
		\Mn-Co-Au
		\Yb-Er-V
		\Nb-V-W
		\Yb-Nd-V
		\Yb-Th-Ti
		\K-Rb-Mg
		\Ce-Gd-Sc
		\Dy-Lu-W
		\V-Fe-Cu
		\Yb-Zr-Sc
		\La-Ce-Y
		\Ni-Pd-Ru
		\La-Pr-Gd
		\La-Ce-Pr
		\Eu-Th-Mo
		\Nd-Ho-Sc
		\Hf-Ti-Nb
		\Tb-Yb-Th
		\Tb-Pr-Ti
		\Er-Pu-Nb
		\Re-Hg-Ru
		\Ba-Yb-Mn
		\Lu-Zr-Nb
		\Nd-Y-Zr
		\Rb-Na-Li
		\Sm-Dy-Er
		\U-Nb-W
		\Ce-Lu-Zr
		\Pd-Rh-C
		\Tb-Ce-Sm
		\Eu-Cr-Mo
		\Y-Ti-Mo
		\Li-Ce-Lu
		\Yb-Gd-Pu
		\Cd-B-Pb
		\Th-Ti-Mo
		\Ba-La-Nd
		\Er-Lu-V
		\K-Rb-Cr
		\Cd-Fe-Bi
		\Al-Tl-Cd
		\Pr-Nd-Gd
		\Nd-Ho-Lu
		\Ce-Ho-Tm
		\La-Pu-Nb
		\Ge-Au-C
		\Ca-Y-Lu
		\Yb-Gd-Y
		\Na-V-Mo
		\Y-Ho-Th
		\Ba-Sr-Y
		\Tb-Dy-Lu
		\La-Gd-Dy
		\Y-Er-Mo
		\Ce-Sm-Pu
		\V-Pd-Ru
		\Li-Tb-Er
		\Yb-Pr-Ta
		\Yb-Gd-Er
		\Y-Ho-Cr
		\Pm-Cr-Mo
		\Eu-Th-Sc
		\La-Er-Ta
		\Ba-Y-Mo
		\Co-Hg-Ir
		\Lu-Pu-Ti
		\Y-Tm-Lu
		\Ba-Ca-Nd
		\K-Li-Mo
		\V-Hg-Bi
		\Tb-Gd-Ta
		\La-Pm-Lu
		\Ir-Ru-C
		\Ca-Y-Ti
		\Tb-Yb-Cr
		\Li-Sc-Mo
		\Er-Hf-Ti
		\La-Ta-W
		\La-Dy-Ti
		\Ce-Nd-Sc
		\Co-Pt-C
		\Tm-Lu-Ta
		\Na-Mg-V
		\Ce-Eu-Cr
		\Cs-Li-Mg
		\Gd-Pu-Mo
		\Re-Pd-Rh
		\Yb-Nd-Tm
		\Mg-U-V
		\Fe-Pd-Ru
		\K-Rb-Hf
		\Ba-Ca-V
		\Rb-Mg-V
		\La-Sm-Cr
		\Y-Cr-Mo
		\Cs-Ti-V
		\Tb-Th-Ti
		\Pm-Gd-Er
		\La-Er-Th
		\Tb-Gd-Sc
		\Ce-Pm-Lu
		\Pm-Sm-Pu
		\Hf-Sc-Ti
		\Y-Pu-Cr
		\Be-Zn-In
		\Yb-U-Ta
		\Nd-Y-Ta
		\Ce-Hf-Zr
		\Cs-K-Mo
		\Ag-Pd-C
		\Sm-Tm-Mo
		\Ho-Cr-Mo
		\Yb-U-Cr
		\Tb-Lu-Th
		\Yb-Th-Mo
		\Ca-Tb-Ce
		\Tb-Dy-Mo
		\Zn-Cd-Si
		\Pm-Pu-V
		\Yb-Nb-Mo
		\Hf-U-Ta
		\Li-Sm-Er
		\Li-Zr-Ti
		\Na-Ta-Cu
		\Pr-Er-Th
		\V-In-Mo
		\Er-Th-W
		\Pm-Dy-Th
		\Tb-Gd-Cr
		\Nd-Zr-Sc
		\Sr-Ca-Ti
		\Tm-Th-Cr
		\Gd-Sc-Cr
		\Li-Gd-Lu
		\Ag-Os-Rh
		\La-U-V
		\Th-Ta-V
		\Co-Pd-Pt
		\Ce-Ho-Sc
		\Cu-Re-Rh
		\Yb-Sc-Ti
		\Li-La-Nb
		\Cu-Ag-Au
		\Eu-Sc-Nb
		\Rb-Na-Hf
		\Co-Rh-Au
		\Tb-Ho-V
		\Ce-Gd-Er
		\Tb-Ce-Ti
		\Gd-Dy-Er
		\Zn-In-Ge
		\Ce-Nb-Mo
		\Y-Ho-Sc
		\Pm-Pr-Er
		\Li-Pr-Hf
		\Er-Lu-Cr
		\Li-Gd-Cr
		\Li-Gd-Mo
		\Yb-Lu-Th
		\Pu-Nb-W
		\La-Tb-Lu
		\Li-La-Tm
		\Cu-Ni-Ag
		\Yb-Pr-Th
		\La-Sc-W
		\Fe-Ag-C
		\Mg-Mn-Fe
		\Fe-Ag-Ru
		\Nd-Cr-Mo
		\La-Pr-Ti
		\Cr-Cu-Mo
		\Tb-Er-Sc
		\Li-Lu-Nb
		\Y-Lu-V
		\Lu-Ti-V
		\Sn-B-Pb
		\Li-Pr-Er
		\Re-Hg-Ir
		\Yb-Dy-Tm
		\Tb-Pm-V
		\Cs-K-Mg
		\Ni-Ir-Pt
		\Ca-Sc-Mo
		\Eu-Y-Th
		\Zr-Sc-Ti
		\Yb-Pu-W
		\K-Li-Zr
		\Yb-Pr-Gd
		\Y-Pu-Nb
		\Ce-Th-W
		\Ce-Y-Er
		\Nb-V-Bi
		\V-Cu-Bi
		\Y-U-Nb
		\Fe-Cu-Rh
		\Re-Pt-Rh
		\Pu-Ti-Mo
		\Re-Ir-Pt
		\Ca-Yb-Pu
		\Nd-Sm-V
		\Na-Sr-Ca
		\Ho-Pu-Ta
		\Ce-Ho-Cr
		\Ce-Zr-Nb
		\Pm-Th-V
		\La-Gd-Lu
		\Dy-Lu-Zr
		\Ta-Ti-W
		\Nd-Dy-Ho
		\Y-Ho-Ta
		\Sr-Y-Mo
		\La-Pr-Th
		\Cr-Cu-Bi
		\Pm-Lu-Mo
		\La-Pm-V
		\Sm-Er-Pu
		\Y-Pu-Mo
		\Ba-Sr-Ti
		\Ta-V-Cu
		\Ga-Hg-B
		\La-Pu-Cr
		\Nd-Pu-Ta
		\Li-Sc-Ta
		\Ca-Eu-Mn
		\Pr-Y-Er
		\Lu-Pu-W
		\Dy-Ti-Cr
		\Ce-Y-Tm
		\Na-Zr-Nb
		\La-Lu-Th
		\Tb-Nd-Er
		\Eu-Sc-Mo
		\Ca-Ce-Sc
		\Pr-Lu-W
		\Pm-Ho-Pu
		\Nd-Ta-Ti
		\La-Lu-V
		\Co-Pd-Au
		\Pr-Sm-Tm
		\Li-Mg-Mn
		\Lu-Cr-W
		\Eu-Hf-Th
		\Li-Gd-Pu
		\Er-Pu-Ta
		\Yb-Nd-Y
		\La-Eu-Mo
		\Gd-Ho-Pu
		\Cs-Li-Nb
		\La-Ho-Th
		\Rb-Zr-Nb
		\La-Pr-Mo
		\Nd-Gd-Ta
		\Th-U-Nb
		\Na-Mg-Cr
		\Ce-Sc-Nb
		\Eu-U-V
		\Na-Be-Al
		\Yb-Lu-Cr
		\Gd-Dy-Th
		\Pm-Sm-Mo
		\Cs-V-Mo
		\Pm-Pu-Cr
		\Gd-Y-Th
		\Cs-K-V
		\Tb-Yb-V
		\K-Ta-Mo
		\La-Yb-Mn
		\Rb-Li-Hf
		\Li-Gd-Er
		\La-Zr-Ti
		\Ce-U-Ti
		\Ba-Gd-Y
		\Ba-Y-Ti
		\Cu-Ni-C
		\Nd-Ti-V
		\Cd-Si-Pb
		\Nd-Tm-Pu
		\Tb-Er-Zr
		\Pr-Ho-Er
		\U-Cr-W
		\Nd-U-Cr
		\Fe-Pd-C
		\Na-Li-Fe
		\Ba-Ca-Yb
		\Sm-Er-Tm
		\Ca-La-Eu
		\Ca-La-Sc
		\Mn-Co-Pb
		\Nd-Sm-Tm
		\Tc-Pd-Pt
		\Cs-Ta-Ti
		\Ca-Eu-Mo
		\Li-La-Er
		\Ni-Ir-Ru
		\Fe-Ni-Rh
		\Pu-Nb-Mo
		\Yb-Y-Nb
		\Eu-Y-Hf
		\Re-Ni-Pd
		\Pm-Sm-Dy
		\Cu-Re-Ni
		\Lu-Nb-V
		\Tm-Lu-Cr
		\Ce-V-W
		\Ca-Ce-Lu
		\Ce-Sm-Gd
		\Re-Tc-Ni
		\Ag-Ru-Au
		\Mg-Cr-Fe
		\Ba-Ca-La
		\Ca-Yb-Tm
		\Ni-Os-Pt
		\Tl-Cd-Ge
		\Mg-Zr-Ti
		\Ba-Sc-V
		\Fe-Ru-Rh
		\Pr-Hf-Ta
		\Nd-Y-Er
		\Ho-Tm-Cr
		\Ce-Pu-V
		\Ce-Y-Ho
		\Eu-U-Cr
		\Tb-Sm-Dy
		\Ce-Pr-Ta
		\Sc-Ti-W
		\Sm-Dy-Y
		\Pm-Dy-Mo
		\Er-Lu-Ti
		\La-Gd-Th
		\La-Mn-V
		\Ba-Ti-Mo
		\Tb-Ce-Pr
		\Ag-Ru-Rh
		\Ba-Pr-Y
		\Yb-Dy-V
		\La-Pr-Sm
		\Li-Ta-Cu
		\La-Ho-Er
		\Tl-V-In
		\Tb-Ho-Tm
		\La-Y-W
		\Ru-Pt-Rh
		\Sm-Y-Lu
		\Tm-Lu-V
		\Ce-Er-Nb
		\Cr-W-Au
		\Yb-U-Ti
		\La-Eu-V
		\Pr-Nd-U
		\Li-Nb-Cu
		\Cu-Pt-W
		\Ni-Ag-Os
		\Yb-Hf-Zr
		\Li-Pm-Pr
		\Cs-Nb-V
		\Y-Ti-W
		\Fe-Ag-Os
		\Hf-Ta-Ti
		\La-Sm-Er
		\Er-Hf-Zr
		\Ir-Os-Rh
		\Dy-Ho-Er
		\Ba-Nd-V
		\Ag-Pd-Au
		\K-Rb-Nb
		\Pr-Sm-Lu
		\Nd-Dy-Th
		\Li-Pm-Tm
		\Ba-Yb-Nd
		\Tb-Pm-Er
		\La-Pm-Pu
		\Sm-Dy-Th
		\V-Cr-Fe
		\Tb-Pm-Pr
		\Ho-Pu-Mo
		\Ba-Yb-Eu
		\La-Sm-Mo
		\Li-Tb-Cr
		\Tb-Er-Ti
		\Na-Mg-Nb
		\Pr-Mo-W
		\Ce-Tm-Ta
		\Er-Ta-Nb
		\Gd-Ho-Th
		\Tb-Ho-Mo
		\Nd-Gd-Ho
		\Li-Pr-Sm
		\Cr-Ag-Au
		\Lu-Pu-Cr
		\Nb-Hg-Mo
		\Tb-Gd-Er
		\La-Yb-Pu
		\Fe-Ir-Ru
		\Sm-Tm-Pu
		\Th-Sc-W
		\Tb-Gd-Lu
		\Tb-Pm-Sm
		\Pr-Y-Ti
		\Er-Cr-W
		\Th-Sc-Ti
		\Y-Tm-Ta
		\La-Lu-Ta
		\Ga-Hg-Ge
		\U-Ti-V
		\Ce-Pr-Th
		\Ho-Th-W
		\Pm-Gd-Y
		\Li-Ho-Er
		\Cr-Fe-Ni
		\Rb-Ta-Mo
		\Ce-Pr-Tm
		\Li-Pr-Ho
		\Yb-Ta-Ti
		\La-Lu-Zr
		\Pm-V-Cr
		\Hg-Bi-Sb
		\Ce-Nb-V
		\K-V-Cr
		\Ca-Eu-Y
		\Y-Sc-V
		\Nd-Y-V
		\Ag-Ir-C
		\Ni-Ru-Rh
		\Li-Nd-Pu
		\Cs-Rb-Li
		\Na-Li-V
		\Gd-V-Cr
		\Co-Ag-Ir
		\B-Sb-Pb
		\Pr-Y-Tm
		\La-Zr-Nb
		\Tb-Pu-V
		\Re-Ni-Ir
		\Yb-Nb-W
		\Pm-Er-Pu
		\La-Y-Tm
		\Be-Si-Sn
		\Cr-Mo-W
		\Ce-Lu-Ta
		\Ce-Lu-Th
		\Li-Y-Ti
		\Tb-Ho-Lu
		\Tb-Sm-Th
		\Pm-Nd-Ho
		\Sc-U-V
		\Li-Nd-Ho
		\La-Nd-W
		\La-Yb-Pr
		\Ce-Er-Ta
		\Rb-Ti-Nb
		\La-Cr-Fe
		\Ce-Nd-V
		\Zr-Ta-Ti
		\Lu-V-W
		\Sm-Dy-Ho
		\Nd-U-V
		\Nd-Dy-Ta
		\Pm-Ho-Lu
		\Na-Ti-Mo
		\Pr-Er-V
		\La-Ti-Cr
		\Al-Ga-Pb
		\La-Pm-Dy
		\Sm-Lu-Pu
		\Gd-Er-Ta
		\Nd-Sm-Y
		\Tb-Pm-Nd
		\Al-Hg-Bi
		\V-Hg-Ru
		\Pm-Pr-Pu
		\Ce-Th-Nb
		\K-Hf-Ta
		\Pr-Dy-Ta
		\Li-Y-Cr
		\La-Er-Ti
		\Dy-Y-V
		\Lu-Pu-Ta
		\Nd-Tm-V
		\Pr-Lu-Ta
		\La-Pm-Gd
		\Tl-Cd-B
		\Yb-Y-Ta
		\Os-Pd-Ru
		\Sm-Dy-V
		\Pu-Np-Mo
		\Nb-V-Hg
		\Pr-Dy-Er
		\Nd-Tm-Th
		\Ho-Sc-Cr
		\Zr-Sc-Ta
		\Pm-Tm-Lu
		\V-Ag-Mo
		\Pr-Dy-Lu
		\Zn-Cd-Pb
		\Li-Sm-Pu
		\La-Tb-Pr
		\Cr-Ni-Ag
		\La-U-W
		\La-Ce-Nd
		\Yb-Lu-Ti
		\Yb-Tm-Lu
		\Na-Ca-Ti
		\Co-Hg-Pb
		\Pm-Pr-V
		\Be-Si-Ge
		\Sr-Y-Ti
		\Yb-Gd-Sc
		\V-Pd-W
		\Re-Ir-Os
		\Ce-Gd-Lu
		\Li-Ho-Pu
		\Be-Zn-Ga
		\Y-U-W
		\Ga-Hg-Bi
		\Ba-Pr-Gd
		\La-Tm-W
		\Li-Tb-Ce
		\Yb-Eu-Hf
		\Si-Ag-Pb
		\Ce-Cr-W
		\Gd-Mo-W
		\Th-Sc-Mo
		\Ag-Ir-Os
		\Cu-Re-Tc
		\Ce-Pm-Th
		\Ba-Fe-Ni
		\Ir-Os-Pd
		\Cr-Cu-Pb
		\La-Ce-Pm
		\Li-La-Y
		\Ca-Yb-Ti
		\Fe-Pd-Rh
		\Ce-Eu-W
		\Cs-Pr-Cr
		\Nb-Cu-W
		\Fe-Cu-Bi
		\Tb-Er-Mo
		\La-Th-Nb
		\Sm-Er-Lu
		\Yb-Ti-Nb
		\Al-Zn-Si
		\Yb-Ho-Zr
		\Cs-K-Li
		\Pr-Hf-Ti
		\Fe-Co-Bi
		\Tb-Pm-Cr
		\Lu-Th-Ti
		\Y-Ho-W
		\Li-Ce-Pr
		\Sm-Y-V
		\Ni-Pd-C
		\Sc-Ta-Ti
		\Zn-In-Si
		\Y-Lu-Th
		\Ce-Eu-Mo
		\Yb-Gd-Cr
		\Tb-Tm-Cr
		\Yb-Th-Ta
		\Tb-V-W
		\Ta-Tl-Cu
		\Hg-Mo-Pb
		\Sm-Er-Th
		\Co-Re-Rh
		\Co-Ag-Rh
		\La-Tm-Cr
		\Hf-Zr-Sc
		\Fe-Ni-Ru
		\Nb-Mo-W
		\Gd-Th-Sc
		\Pd-Pt-C
		\Pm-Dy-Er
		\Co-Ni-Ag
		\Gd-Dy-Tm
		\Tm-Pu-V
		\Nd-Dy-Lu
		\Dy-Er-Zr
		\Tb-Er-V
		\Nd-Tm-Ta
		\Sm-Er-Mo
		\Li-La-Zr
		\Th-Nb-W
		\Tb-Ce-Sc
		\Gd-Ho-Sc
		\Pr-Dy-Cr
		\Hf-Zr-Nb
		\Ca-Pu-Mo
		\Ti-Nb-W
		\Co-Ir-C
		\Ba-Sr-Nd
		\Ba-Yb-Mo
		\Yb-Th-Nb
		\Li-Nd-Ta
		\Ce-Er-V
		\Co-Re-Ag
		\Yb-Er-Lu
		\Re-Pd-Pt
		\Ni-Pd-Au
		\Ho-Sc-Ta
		\Cr-Fe-Au
		\Tb-Sm-Er
		\Ce-Gd-Pu
		\Li-Ce-Pm
		\Nd-Y-Ti
		\Li-Nd-Y
		\Nd-Y-Tm
		\La-Ho-Pu
		\La-Ce-Zr
		\La-Ti-W
		\Ag-Os-Ru
		\Pt-W-Au
		\Cu-Bi-Os
		\Pr-Nd-Ti
		\Fe-Co-Os
		\Li-Nb-V
		\Y-Sc-Cr
		\Be-Si-Bi
		\Li-Hf-Ta
		\Th-V-Cr
		\Ho-Mo-W
		\Pm-Y-Cr
		\Th-Zr-Ti
		\Gd-Ho-V
		\Yb-Pu-V
		\Tm-Ti-W
		\Cs-Mg-Ti
		\La-Gd-Mo
		\Li-Nd-Sc
		\Co-Ag-Pd
		\Ba-V-Mo
		\Pr-Gd-W
		\Ce-U-V
		\Tb-Dy-Ho
		\Sr-Ca-Sc
		\Nd-Sc-U
		\Li-Y-Ta
		\Y-Th-Cr
		\Cr-Cu-Ag
		\Fe-Os-Pd
		\Pm-Sm-Cr
		\Cu-Pb-W
		\Nd-Ho-Th
		\Pm-Nd-V
		\Ce-Nd-Lu
		\Fe-Ni-Pb
		\U-V-W
		\Nd-Ta-V
		\Dy-Pu-Zr
		\Ce-Pu-Nb
		\La-Ce-Ta
		\Tm-Lu-Th
		\La-Ho-Cr
		\Ba-La-Fe
		\Tm-Ti-Cr
		\Eu-Y-Pu
		\Yb-Y-Hf
		\La-Yb-Mo
		\Hf-Th-Nb
		\Nd-Sm-Pu
		\Na-Hf-Mg
		\Fe-Ag-Au
		\Li-Pu-Nb
		\Pr-Sm-Pu
		\Tb-Er-Lu
		\Sm-Gd-Er
		\Co-Cu-Au
		\Re-Ag-Os
		\Ru-Rh-Au
		\Mn-Tl-Ni
		\Pr-Er-Ta
		\La-Cr-Mo
		\Dy-Pu-Ta
		\Ta-In-Mo
		\Be-In-Ge
		\Y-Lu-Cr
		\Cd-Bi-Mo
		\Yb-Dy-Mo
		\Na-Hf-Ti
		\Ba-Nd-Gd
		\Ca-Mn-Co
		\Li-Nd-Zr
		\Co-Os-Pt
		\Co-Ru-Pt
		\Yb-Pu-Ta
		\K-Ta-Nb
		\Tb-Yb-Ta
		\Tb-Yb-Pr
		\Sc-Ti-Mo
		\Fe-Co-Ir
		\Fe-Cu-Ag
		\Hf-Th-Ti
		\Tb-Y-Sc
		\Hg-Ge-Pb
		\K-Nb-V
		\Li-La-Ta
		\Er-Th-Zr
		\K-Zr-Ta
		\Ce-Y-U
		\Gd-Lu-Mo
		\Li-Tb-Tm
		\Fe-Cu-Ni
		\Cu-Os-Pd
		\Tb-Tm-Th
		\Pr-Tm-V
		\Li-Y-Nb
		\Dy-Ho-Th
		\Na-Cu-Mo
		\Yb-Eu-Cr
		\Li-Ti-Cr
		\Cs-Hf-Ta
		\Co-Cu-Pt
		\Rb-Ti-Mo
		\Al-Ga-Bi
		\V-Cr-W
		\Pm-Gd-Lu
		\Li-La-Pu
		\Ce-Ho-Er
		\Ce-Hf-Nb
		\Ag-Rh-Au
		\Ce-Pu-Mo
		\Bi-B-Sb
		\Ho-Er-Zr
		\La-Ta-V
		\Tb-Dy-Tm
		\Pm-Lu-V
		\Tb-Tm-Mo
		\Zn-Cd-Bi
		\Ce-Nb-W
		\Sm-Tm-V
		\Th-U-W
		\Tb-Nd-V
		\Pr-Sm-Y
		\La-Pr-Lu
		\Ho-Sc-V
		\Ag-Bi-Os
		\Li-Tb-Nd
		\Sr-Y-Sc
		\Co-Re-Ni
		\Y-Tm-V
		\Ce-V-Mo
		\Pm-Tm-Th
		\Sm-Dy-Mo
		\Pd-Ru-Rh
		\Hg-Bi-Os
		\Sc-U-Nb
		\Yb-Pr-Pu
		\Al-Tl-In
		\Ho-Pu-V
		\Rb-Zr-Ti
		\Tm-Ti-Mo
		\Pr-Sm-Gd
		\Gd-Tm-Ta
		\Mn-V-Ag
		\Cr-Cd-Bi
		\Tb-Ta-W
		\V-Ag-Bi
		\La-Ho-Lu
		\La-Dy-Er
		\Li-Gd-Tm
		\Y-Er-V
		\Li-Sc-Ti
		\Np-Cr-Mo
		\Ag-Mo-Pb
		\Co-Hg-Bi
		\Mn-Tl-V
		\Eu-Y-Zr
		\Cu-Ir-Os
		\Tb-Gd-Ti
		\Pr-Hf-Th
		\Tb-Ce-Cr
		\Ag-Ir-Rh
		\Tl-Zn-In
		\Li-Sm-Cr
		\Nd-Sm-Lu
		\Re-Ni-Rh
		\Yb-Zr-Ti
		\La-Dy-V
		\Mn-Ru-Au
		\Dy-Tm-Th
		\Pr-Nd-V
		\Sm-Ho-Th
		\Eu-Nb-Mo
		\Y-Er-Ta
		\Ce-U-W
		\Sn-Ge-B
		\Nd-Ti-Mo
		\Hf-Sc-U
		\Tl-Ga-Ge
		\Tm-Ta-Mo
		\Fe-Ru-Au
		\Ho-Er-Sc
		\Yb-Th-W
		\Nd-Er-Mo
		\Cs-Rb-Ta
		\Lu-Zr-Ti
		\Zn-Ga-Ge
		\Pr-Pu-Mo
		\La-Eu-Pu
		\Yb-U-W
		\Yb-Gd-Th
		\Tl-Ge-Pb
		\Tb-Ce-Tm
		\Gd-Lu-W
		\La-Dy-Mo
		\Co-Re-Pt
		\Yb-Sc-Cr
		\Al-Zn-Ge
		\Tl-Ni-Pb
		\K-Rb-Ti
		\Y-Th-U
		\Pm-Y-Er
		\Ce-Sm-W
		\La-Ho-V
		\Ce-Y-W
		\Na-Ta-Mo
		\Tb-Ce-Er
		\Sm-Gd-V
		\Cs-Li-Cr
		\Pr-Pu-Ti
		\Dy-Zr-Ta
		\Ca-Fe-Co
		\Lu-Ta-W
		\Al-Ga-Si
		\Pr-Th-Ta
		\Na-Li-Hf
		\Ir-Ru-Rh
		\Fe-Ru-C
		\Ho-V-Mo
		\Lu-Th-Mo
		\Pu-Ta-V
		\Lu-Th-Cr
		\Ca-Tm-V
		\La-Dy-Cr
		\Ce-Sc-Cr
		\Li-La-Sm
		\Mn-V-Cu
		\Ni-Ag-C
		\Li-Tb-Sm
		\Cs-Ta-Mo
		\Li-Ho-Mo
		\Cs-Pr-Hf
		\Hf-Zr-Ti
		\Sm-Th-U
		\Pr-Er-Mo
		\Yb-Dy-Ta
		\Tb-Gd-Dy
		\La-Yb-U
		\Mn-Tl-Cu
		\Yb-Pr-Ho
		\La-Y-Ho
		\Ce-Pr-Ti
		\La-Nd-Th
		\Ce-Y-Nb
		\Ca-Lu-V
		\Tb-Pr-V
		\Dy-Er-Pu
		\Ni-Pt-Rh
		\Ni-Rh-C
		\Hf-Mg-U
		\Ca-Tm-Pu
		\Al-Hg-Ge
		\Nd-Ho-Mo
		\Li-Sm-Ta
		\Pm-Ho-Tm
		\Ca-Yb-Sc
		\Co-Cu-Pb
		\Li-Tm-Lu
		\Er-Sc-Mo
		\Sr-Mn-V
		\Pr-Gd-Cr
		\Na-Li-Cr
		\Na-V-Cu
		\Na-Zr-Ti
		\Pd-W-Au
		\Li-Gd-Ta
		\Li-Lu-Zr
		\Rb-Li-Mg
		\Co-Hg-Ru
		\Dy-Y-Ta
		\La-Nd-Tm
		\Al-Sn-Pb
		\Na-Zr-Ta
		\Er-Tm-Ti
		\Sr-Ca-Nd
		\Rb-Ti-V
		\Y-Nb-V
		\Tb-Nd-Sc
		\Rb-Na-Zr
		\Cd-Ga-Si
		\Ca-Tb-Yb
		\Sn-Ge-Pb
		\Yb-Gd-Zr
		\Y-Ho-Lu
		\Yb-Y-Cr
		\Ce-Gd-Ho
		\Ba-Sm-Gd
		\Cr-Cd-Fe
		\La-Y-Mo
		\Ba-Mn-Ni
		\Gd-Pu-Cr
		\Tb-Th-W
		\Ba-Mn-V
		\Cu-Ni-Pt
		\Lu-Ti-Cr
		\Pr-Gd-Pu
		\Er-Ti-Nb
		\La-Nd-V
		\Mn-Co-Ru
		\Yb-Eu-Pu
		\Li-Pm-Ho
		\La-Sm-Lu
		\La-Eu-Nb
		\Sn-Ge-Bi
		\La-Er-V
		\Yb-Pr-Cr
		\Ce-Lu-V
		\Rb-Nb-Mo
		\Tl-Ag-Ge
		\Gd-Lu-Th
		\Y-Er-Lu
		\Tb-Lu-V
		\Fe-Ir-Os
		\Hg-Os-Ru
		\Ba-Yb-Y
		\Nd-Y-Mo
		\Ce-Sm-Tm
		\Ba-Pr-V
		\La-Pr-Dy
		\Rb-V-Mo
		\Ba-Ca-Pm
		\La-Tm-Pu
		\Lu-Th-Nb
		\Al-Ge-Bi
		\Li-Y-Lu
		\K-Mg-Zr
		\Hg-Ir-Ru
		\Nd-Th-Mo
		\Cs-Ta-Nb
		\Cu-Pd-W
		\Yb-Dy-Cr
		\Dy-Th-Cr
		\Hg-Ge-Bi
		\Cu-Ni-Pd
		\Y-Ti-V
		\Sm-Ho-V
		\Dy-Y-W
		\Tb-Nd-Ho
		\Ta-Ti-Mo
		\Tm-Lu-Ti
		\Li-Tm-V
		\Nd-Gd-Tm
		\Na-Cr-Fe
		\Li-Cr-Mo
		\Tb-Pm-Mo
		\Lu-Mo-W
		\Th-Ta-Ti
		\Cd-Ge-B
		\Th-Ti-Nb
		\Yb-Mo-W
		\Nd-Lu-W
		\Al-Cd-Pb
		\Tb-Nd-Th
		\Ag-Os-Pd
		\Yb-Cr-W
		\Ce-Zr-Sc
		\Co-Hg-Os
		\Ba-Sr-V
		\Nd-Y-Cr
		\Tb-Tm-Ta
		\Tb-Er-Th
		\Re-Ni-Ru
		\Y-U-Ti
		\Yb-Gd-Lu
		\Sc-Ti-Nb
		\Ce-Sm-Th
		\Pr-Gd-Ti
		\Nd-Y-Lu
		\Dy-Y-Cr
		\Ca-La-Pu
		\Ce-Lu-Nb
		\Cu-Ru-Rh
		\Si-Sn-Pb
		\Cu-Re-Ag
		\Ga-Ge-Bi
		\La-Eu-Th
		\Tb-Ce-Nd
		\Yb-Pr-Lu
		\K-Ti-Nb
		\K-V-Fe
		\Lu-Pu-Mo
		\Pm-Er-Cr
		\Pr-Sm-V
		\Al-Si-Pb
		\Sm-Gd-Th
		\Ni-Ir-Os
		\Cu-Re-Ir
		\Tl-Ge-B
		\Ba-Pm-Gd
		\Yb-Er-Pu
		\Fe-Bi-Os
		\Yb-Lu-W
		\Cs-Pr-V
		\Ca-Tb-Nd
		\Li-Ho-Lu
		\Tb-Er-Ta
		\Ca-La-Mo
		\Gd-Zr-Ti
		\Gd-Ho-Tm
		\Nd-Lu-Pu
		\Tb-Yb-Zr
		\La-U-Cr
		\Pm-Th-Cr
		\Ba-Ca-Eu
		\La-Ce-V
		\U-Nb-V
		\Ca-Ce-Pm
		\Ho-Er-Lu
		\Y-Er-Th
		\Gd-Tm-Pu
		\Yb-Tm-Ta
		\La-Eu-W
		\Rb-Hf-Ti
		\Li-Er-Ti
		\Pm-Er-Lu
		\Li-Hf-Sc
		\Sr-V-Fe
		\Pm-Tm-Mo
		\Er-Th-Cr
		\Ca-Lu-Ti
		\Li-Y-Tm
		\Ba-Ca-Ti
		\Tb-Pm-Dy
		\Sm-U-Cr
		\Ce-Eu-Ta
		\Nd-Y-Th
		\Fe-Co-Ru
		\Gd-Y-Ho
		\Cs-Mg-Nb
		\Ho-Th-V
		\Y-Th-Ti
		\Li-La-Lu
		\Li-Ta-Ti
		\Ce-Th-Sc
		\Li-Ti-Nb
		\Yb-Ho-Tm
		\K-Rb-Li
		\Er-Hf-Th
		\Cu-Tc-Mo
		\Ni-Rh-Au
		\Ni-Ag-Pd
		\Cr-Fe-Bi
		\Y-Sc-Ta
		\Tb-Nd-Lu
		\Dy-Er-Tm
		\Rb-Hf-Ta
		\Li-Tb-Lu
		\Sm-Gd-Y
		\Ir-Pd-Ru
		\Pm-Nd-Pu
		\Ba-Pm-Y
		\Y-Hf-Ti
		\Nd-Gd-Zr
		\Th-U-Ti
		\K-Mg-Fe
		\Ni-Pt-C
		\Cu-Pd-Ru
		\Gd-V-Mo
		\Co-Ag-Bi
		\Li-Ce-Cr
		\Tm-Th-Ta
		\Pm-Dy-V
		\Er-Sc-Ta
		\Sm-Th-Nb
		\Yb-Mn-V
		\K-V-Mo
		\Dy-Tm-Lu
		\La-Tb-W
		\Yb-Nd-Th
		\Y-Ho-Zr
		\Yb-Pu-Nb
		\Ce-Pr-Sm
		\Co-Re-Pd
		\Ce-Pu-Cr
		\Ag-Mo-Au
		\Ca-V-Mo
		\Tl-Cu-Ag
		\Tb-Lu-Cr
		\Be-Sn-Ge
		\Y-Er-Tm
		\Nd-Dy-Pu
		\Nd-Ho-Er
		\Be-Zn-Si
		\La-Pr-Cr
		\In-Ga-Ge
		\Li-Fe-Ni
		\Ni-Ru-C
		\Gd-Sc-W
		\Gd-Cr-Mo
		\Er-Tm-Ta
		\Cu-Mo-W
		\Pm-Ho-Mo
		\Hf-Th-Ta
		\Sc-Mo-W
		\Cr-Cd-Mo
		\U-Ta-Nb
		\Tb-Th-Cr
		\Co-Cu-Ag
		\Zn-Sn-B
		\Pm-Lu-Cr
		\Ba-Eu-Mo
		\Er-Lu-Pu
		\Ni-Ir-Rh
		\Li-Mn-Cu
		\Mg-U-Ti
		\Gd-Ti-W
		\Co-Ni-Pd
		\Yb-Er-Zr
		\Yb-Ho-Mo
		\Cu-Ir-C
		\Nd-Er-Th
		\U-Ti-Nb
		\Sm-Lu-Ta
		\Mn-Ag-Au
		\Pm-Th-Mo
		\Er-Lu-Zr
		\Nd-Tm-Lu
		\Sc-Ta-W
		\Dy-Er-Ta
		\Eu-Pu-V
		\Y-Th-Nb
		\Eu-Mo-W
		\Nd-Gd-Dy
		\Cs-Ti-Nb
		\La-Nd-Er
		\Nd-V-Cr
		\Zn-Si-Pb
		\Mg-Cd-Fe
		\Re-Ag-Pd
		\Sr-V-Mo
		\Fe-Ag-Bi
		\V-Cu-Ru
		\Sm-U-Ta
		\Ce-Pu-Ti
		\Na-Fe-Cu
		\Li-Sm-Ho
		\Yb-Ho-V
		\La-Yb-Zr
		\Li-Pu-V
		\Tm-V-Cr
		\Ta-Cu-W
		\Tb-Pm-Lu
		\Ni-Ag-Rh
		\Pm-Y-Pu
		\Pu-Zr-Ta
		\Eu-Pu-Zr
		\Th-Zr-Nb
		\K-Rb-Zr
		\Sm-Lu-Cr
		\Mg-Cd-Mo
		\Tb-Pr-Ho
		\Ce-Eu-U
		\La-Nd-Pu
		\Ru-Pt-C
		\La-Lu-Pu
		\Pm-Tm-V
		\Yb-Th-U
		\Ce-Pm-Ho
		\Co-Ni-Au
		\Si-Sn-Ge
		\Er-Th-Ti
		\Os-Pd-Rh
		\Hf-Mg-Zr
		\Mo-W-Au
		\Sm-Gd-Lu
		\Ca-Tb-Lu
		\Ho-Lu-W
		\Fe-Co-Rh
		\La-Sm-Dy
		\Be-Al-Bi
		\Er-Tm-Pu
		\Sn-Bi-B
		\Nd-Ta-Mo
		\Eu-Th-Cr
		\La-Nd-Sm
		\Pm-Nd-Cr
		\Dy-Th-Zr
		\Pm-Er-Tm
		\Tl-Zn-Ga
		\Y-Pu-W
		\Ba-Pm-Nd
		\Ce-Pr-Y
		\V-Cr-Mo
		\Ce-Th-Cr
		\Yb-Dy-Er
		\La-Er-Sc
		\Li-Mg-Cr
		\Y-Lu-Mo
		\Sm-Y-Nb
		\Ce-Eu-Hf
		\Ag-Ge-C
		\Sm-Ho-Cr
		\Na-Li-Cu
		\Tb-Yb-Sc
		\Pr-Gd-Ho
		\Dy-Y-Mo
		\Er-Pu-Cr
		\Na-Li-Nb
		\Pm-Nd-Er
		\La-Ce-Sm
		\Dy-Y-Ti
		\Ca-Nd-Mo
		\Nd-Sm-Er
		\Yb-Nd-Er
		\Li-Nb-Mo
		\Ca-Ce-Mo
		\Cs-Li-Pr
		\Y-Zr-Ta
		\Sm-Dy-Cr
		\Ho-Tm-Th
		\Dy-Y-Tm
		\Pu-Cr-Mo
		\Ce-Sc-V
		\Sm-U-V
		\La-Sm-Gd
		\Yb-Nd-U
		\Pr-Dy-Ho
		\Ho-Er-V
		\Lu-Th-Zr
		\Yb-Nd-Sc
		\Rb-Ta-Nb
		\Gd-Er-Lu
		\Ho-Sc-Mo
		\Tc-Pd-Rh
		\Ce-Tm-Th
		\Cu-Pt-Rh
		\La-Ho-Tm
		\Sm-Ho-Er
		\La-Gd-Cr
		\Al-Zn-Cd
		\Cs-Pr-Ta
		\Pm-Pr-Nd
		\Os-Pt-Rh
		\Er-Mo-W
		\Gd-Dy-Ho
		\Tb-Er-Tm
		\Ce-Nd-Sm
		\Be-Al-Ga
		\Cu-Tc-Ni
		\Nd-Pu-Mo
		\Ca-La-Tb
		\Be-Zn-Bi
		\Cu-Rh-Au
		\Lu-Pu-Nb
		\Pr-Nd-Th
		\Rb-Na-Mo
		\Yb-Pr-Nd
		\Ce-Pr-V
		\Hf-Mg-Nb
		\Tb-Th-Mo
		\Co-Ag-Os
		\Li-Tb-Ti
		\Yb-Tm-Ti
		\Yb-Y-Th
		\Yb-Ta-V
		\Sc-Ta-V
		\La-Lu-Ti
		\Ca-Ce-Pu
		\Ce-Ho-Ta
		\La-U-Ti
		\Pu-Ta-Mo
		\Yb-Y-Tm
		\Er-Zr-Ti
		\U-Cr-Ag
		\Dy-Tm-V
		\Si-Ag-Bi
		\Tb-Y-V
		\Pr-Gd-Tm
		\Ce-Er-Th
		\Tl-Cu-B
		\Ho-Sc-W
		\Li-Mg-Nb
		\Y-Lu-W
		\Sc-V-Cr
		\Cu-Bi-B
		\Tl-Zn-B
		\Nd-Ho-V
		\Ni-Pt-Au
		\Sm-Er-Cr
		\Li-Lu-Pu
		\Yb-Y-Mo
		\Ce-Th-Zr
		\Pr-Lu-Cr
		\Ir-Os-Ru
		\Er-Cr-Mo
		\Tb-Sm-V
		\Dy-Ti-W
		\Nb-Tl-Mo
		\La-Lu-Mo
		\Ag-Pd-Rh
		\Hf-Sc-Ta
		\Re-Os-Pt
		\La-Th-W
		\Pm-Y-Th
		\Y-Hf-Ta
		\Pr-Y-Ta
		\Na-Hf-Zr
		\Tl-V-Cu
		\Gd-Y-Pu
		\Li-Pu-Cr
		\Pr-Sm-Dy
		\Ce-Gd-Ti
		\Mn-Fe-Ag
		\Y-Th-W
		\Ba-Eu-V
		\Ce-Ho-Zr
		\Gd-Zr-Sc
		\Ce-Y-Zr
		\Mn-Ni-Pb
		\Tb-Pr-Er
		\Nd-Lu-Cr
		\Nb-Cu-Bi
		\Li-Ho-V
		\Li-Sm-Tm
		\Al-Ge-Pb
		\Tb-Pu-Ti
		\Tb-Ta-Mo
		\Nd-Sc-Ta
		\Sr-Sc-V
		\Er-Pu-Ti
		\Mg-Nb-Mo
		\Dy-Pu-V
		\Gd-Tm-Ti
		\Na-Li-Be
		\Sm-Ta-V
		\La-Pr-Y
		\Ce-Ti-Nb
		\Ce-Er-Lu
		\Er-Lu-W
		\Lu-Pu-V
		\Fe-Ir-C
		\Li-Y-V
		\Pm-Pr-Ho
		\Th-Sc-Cr
		\Tl-In-Ge
		\Tm-Pu-Ta
		\Tc-Ni-Rh
		\La-Yb-Y
		\Li-Pr-Ti
		\Sr-Nd-Ti
		\Ce-Sc-W
		\La-Th-V
		\Li-Ce-Zr
		\Ba-La-V
		\La-Y-Lu
		\Ce-Pr-Hf
		\Cs-K-Hf
		\Sm-Gd-Pu
		\Li-Ta-V
		\La-Yb-Lu
		\La-Dy-Zr
		\Y-Pu-Ta
		\Th-Zr-Ta
		\Ho-Tm-Lu
		\Ce-Lu-Cr
		\Tb-Mo-W
		\La-Pu-Ta
		\La-Pr-Tm
		\Cs-Ti-Mo
		\Bi-Sb-P
		\Mn-Cu-Au
		\Mg-Ti-Cr
		\Er-Th-Ta
		\Th-Ti-Cr
		\Cr-Cu-Ni
		\Er-Nb-W
		\Tb-Tm-Lu
		\Li-La-Pr
		\Ce-U-Ta
		\Tc-Ni-Ir
		\Pr-Ho-W
		\Li-Cr-Fe
		\La-Sm-W
		\Fe-Cu-Ir
		\Co-Cu-C
		\Nb-Tl-V
		\Co-Pt-Rh
		\Ce-Er-Zr
		\Re-Ir-Rh
		\Lu-Pu-Zr
		\Eu-Nb-V
		\Ce-Sc-Ta
		\Ir-Pd-Pt
		\Hg-Ir-Os
		\Y-Sc-Mo
		\La-Th-Zr
		\Tl-V-Mo
		\Co-Cu-Os
		\Pm-Y-Mo
		\Ce-Pr-Pu
		\Tb-Pr-Mo
		\Yb-Th-V
		\Pm-Dy-Ho
		\Co-Ru-Au
		\Tb-Yb-Y
		\Gd-Y-Sc
		\Dy-Ho-Zr
		\La-Ta-Nb
		\La-Tm-Ta
		\U-Ti-Cr
		\La-Pm-Y
		\Cs-K-Nb
		\Tb-Nd-Zr
		\Nd-Th-Ta
		\Li-Lu-V
		\Sm-V-Mo
		\La-Nd-Mo
		\Gd-Zr-Ta
		\Ce-Nd-Tm
		\Tb-Yb-Gd
		\Ce-Th-V
		\Pr-U-Ti
		\La-Er-Cr
		\Dy-Y-Pu
		\Li-Pr-Y
		\Ce-Er-Mo
		\Dy-Y-Th
		\Er-Sc-Nb
		\La-Nb-Mo
		\Ca-Ce-Tm
		\Eu-U-Nb
		\La-Sc-U
		\Li-Ce-Er
		\La-Dy-Tm
		\Lu-Ta-Mo
		\Gd-Y-Zr
		\Li-Ce-Mn
		\Cr-Cd-Pb
		\La-Tm-Ti
		\Rb-Mg-Cr
		\Tb-Gd-Pu
		\Eu-Sc-V
		\Co-Ag-Au
		\La-Tb-Ta
		\Rb-Mg-Ti
		\Cu-Ir-Pt
		\Rb-Hf-Nb
		\Gd-Ta-Ti
		\Er-Pu-W
		\V-Cu-Pd
		\Dy-Pu-W
		\Sc-Ti-V
		\Tb-Gd-Zr
		\Li-Tb-V
		\Er-Ta-Mo
		\V-Cr-Hg
		\La-Y-Ti
		\Ta-Cu-Mo
		\Ta-Nb-Bi
		\Nd-Sm-Ta
		\V-Fe-Ag
		\Yb-Eu-Nb
		\La-Nb-V
		\Pd-Au-C
		\Sc-Cr-W
		\Mn-Co-Cu
		\Yb-Nd-Mo
		\Sm-Er-V
		\Ce-Pr-U
		\Ag-Ir-Pd
		\Nd-Ti-Cr
		\Li-Y-Zr
		\La-Ce-U
		\Al-Si-Sn
		\La-Eu-Cr
		\In-Si-Ge
		\V-Cr-Cu
		\Al-Sn-Ge
		\Ba-Mn-Fe
		\Th-Ti-V
		\Cs-Li-Mo
		\Ca-Pu-Ti
		\Al-Si-Bi
		\Li-Mg-Fe
		\Yb-U-V
		\Mn-Tl-Cd
		\Fe-Ir-Rh
		\Tb-Nd-Pu
		\Nd-Er-Zr
		\Ba-Sr-Ca
		\La-Eu-Y
		\Sm-Ho-Lu
		\Y-Ho-Tm
		\Tc-Ir-Pd
		\Cs-Hf-Nb
		\Gd-Ti-Mo
		\Dy-Tm-Mo
		\La-Pm-Th
		\Yb-Pu-Zr
		\Ce-U-Nb
		\Ir-Os-Pt
		\Ce-Sm-Ho
		\Tb-Y-Ta
		\Pm-Y-V
		\Pr-Th-Cr
		\Tb-Ce-W
		\Hg-Bi-B
		\Tb-Ce-V
		\Pr-Y-Ho
		\Cu-Ni-Rh
		\Fe-Co-Pb
		\Pr-Tm-Pu
		\Zn-Sn-Ge
		\Yb-Eu-Y
		\Cd-Fe-Pb
		\La-Gd-Sc
		\Ga-Bi-B
		\Sm-Gd-Tm
		\Co-Pt-Au
		\Ce-Er-Hf
		\Tb-Ce-Y
		\Sr-Ti-Mo
		\Cr-Fe-Cu
		\Yb-Hf-U
		\Eu-Hf-Ta
		\Zn-Ge-B
		\Ca-Sc-V
		\Nd-Sm-Cr
		\Ce-Tm-Ti
		\Na-Ca-Mo
		\Tl-Cd-Si
		\Nd-Lu-V
		\Li-Sc-V
		\Pr-Dy-Th
		\Pr-Th-W
		\Co-Cu-Pd
		\Tb-Pu-W
		\Li-Ti-Mo
		\Pm-Y-Lu
		\La-Y-Th
		\Pr-Sm-Er
		\Pr-Ho-Mo
		\Yb-Gd-Ta
		\Gd-Er-Pu
		\Ho-V-W
		\Yb-Dy-Th
		\Li-Mg-Ti
		\Yb-Er-Tm
		\Lu-Ti-Nb
		\Ca-Nd-Tm
		\Tb-Ce-Th
		\Dy-Ta-Mo
		\Sr-Ca-Mo
		\Tb-Ti-Mo
		\Pr-Y-V
		\Yb-Pr-Ti
		\Cd-Ga-Pb
		\Tb-Th-Zr
		\La-Ce-Lu
		\Sm-Pu-Mo
		\Li-Sm-V
		\Ba-Yb-Pr
		\Gd-Ho-Ta
		\Eu-Pu-Cr
		\La-Er-Pu
		\Pm-Lu-Pu
		\Ce-Pr-W
		\U-V-Ag
		\Cs-Li-V
		\Yb-Y-Ho
		\La-Th-Cr
		\Tb-Sc-Cr
		\Eu-Ta-W
		\Cs-Rb-Zr
		\Pm-Gd-Mo
		\Sr-Ti-V
		\Cu-Ru-Au
		\La-Sm-Pu
		\La-U-Nb
		\Pr-Gd-Th
		\Er-V-Mo
		\Ho-Lu-Cr
		\Yb-Er-Ta
		\Y-Lu-Zr
		\Cr-Ag-Bi
		\Sm-V-W
		\Li-Y-Er
		\Gd-Ho-Cr
		\Sc-U-Ti
		\Rb-Mg-Nb
		\Th-Ta-W
		\K-Hf-Zr
		\Ho-Lu-Ta
		\Ba-Nd-Sm
		\Rb-Na-Nb
		\Ce-Sm-Nb
		\Pr-Cr-Mo
		\Ba-Pr-Mo
		\Li-Nd-Tm
		\Re-Os-Ru
		\Eu-Cr-W
		\Nd-Er-Ti
		\Cs-Rb-Mo
		\Tb-Pm-Tm
		\Cs-K-Ti
		\Na-Sr-V
		\La-Ce-Eu
		\La-Gd-Pu
		\Tb-Lu-Pu
		\Th-U-V
		\Li-Zr-Ta
		\Lu-Th-W
		\Tb-Nd-Sm
		\Nb-Bi-Mo
		\Cd-Si-Bi
		\Sm-Ho-Tm
		\Be-In-Si
		\Sr-Ca-Fe
		\Pd-Pt-Au
		\Pm-Pr-Mo
		\La-V-Cr
		\Ce-Gd-Tm
		\La-Ce-Mn
		\La-Th-U
		\K-Li-Hf
		\Ho-Er-Tm
		\Nd-Gd-Lu
		\Ce-Sm-Cr
		\Nd-Dy-Cr
		\Tb-Lu-Zr
		\Er-Pu-Zr
		\Sm-Cr-Mo
		\Pm-Sm-Tm
		\Sc-U-Ta
		\Cs-Cr-Mo
		\Yb-Hf-Ta
		\Pr-Th-V
		\Zn-Ga-Pb
		\Yb-Nd-Ta
		\Er-Zr-Nb
		\Pm-Sm-Lu
		\Mn-Cd-Fe
		\Ba-La-Gd
		\La-Tb-Gd
		\Yb-Nd-Ho
		\Pr-Y-W
		\Er-Ta-Ti
		\Co-Re-Os
		\Co-Ni-Os
		\La-Ce-Ti
		\Sm-Y-Er
		\Pm-Sm-V
		\Yb-Y-Pu
		\Hf-Mg-Ti
		\Pr-Ho-Th
		\Pm-Pr-Dy
		\Pm-Sm-Gd
		\K-Mg-Mo
		\V-In-Fe
		\Yb-Ta-Nb
		\Li-Tb-Sc
		\Y-Th-V
		\Th-Cr-Mo
		\Ba-Eu-Y
		\Ce-Sm-V
		\Pr-Lu-Th
		\Ba-Ca-Fe
		\Ge-Bi-B
		\Ce-Th-Ti
		\Rb-Zr-Ta
		\Yb-Pr-Hf
		\Si-Ge-Au
		\Li-Er-Sc
		\Ba-La-Sm
		\Er-Zr-Ta
		\Ce-Pr-Gd
		\Y-Ta-Ti
		\Na-Ta-Ti
		\Yb-Tm-V
		\Tb-Pm-Th
		\Sm-Pu-W
		\Pr-Tm-Th
		\Ba-Sc-Mo
		\Ag-Bi-Mo
		\Nb-Cu-Mo
		\Tb-Ho-Zr
		\Pm-Sm-Ho
		\Zn-Ge-Bi
		\Tb-Pr-W
		\Ca-Ce-Mn
		\Tm-Ti-V
		\Tc-Ni-C
		\Eu-Pu-W
		\La-Tb-Sm
		\Yb-Lu-Ta
		\Yb-V-Mo
		\Ce-Y-Th
		\Tb-Nd-Ti
		\Cu-Ir-Pd
		\Ba-La-Mo
		\La-Gd-Er
		\Ga-Sn-Ge
		\Tb-Ho-Ta
		\Er-Th-V
		\Pr-Y-Hf
		\Dy-Zr-Ti
		\Lu-V-Cr
		\Pr-Gd-V
		\La-Ti-Mo
		\Tb-Pu-Zr
		\Lu-Nb-Mo
		\Cs-Rb-Mg
		\Fe-Co-Ni
		\K-Ti-Mo
		\Tb-Yb-Mo
		\Fe-Au-C
		\Dy-Ho-Pu
		\Y-Pu-Zr
		\Tb-Pr-Th
		\Sc-Ta-Mo
		\Gd-Ho-Mo
		\Li-Y-Hf
		\Mn-V-Ru
		\Yb-Ti-Mo
		\Sm-Y-Pu
		\La-Nd-Lu
		\Ga-Si-Sn
		\Ba-Y-Sc
		\Nd-Y-U
		\Gd-Lu-Cr
		\Eu-Ta-Mo
		\Er-Tm-Th
		\Be-Ga-Bi
		\K-Li-Fe
		\Tl-V-Ag
		\Co-Os-Ru
		\V-Hg-Mo
		\Fe-Co-C
		\La-Tb-Th
		\Pr-Dy-Pu
		\Ta-Nb-W
		\Li-Pu-Ti
		\Zn-In-Ga
		\Tb-Ce-Zr
		\Ca-Yb-Lu
		\La-Y-Er
		\Yb-Ho-Er
		\La-Lu-Cr
		\Li-Ho-Cr
		\Yb-Pr-Tm
		\Li-Pr-Cr
		\Ho-Th-Sc
		\Pr-Sm-W
		\Tb-Lu-Mo
		\Yb-Eu-Mn
		\Nd-V-Mo
		\Ga-Hg-Pb
		\Pm-Nd-Y
		\Ba-Gd-Mo
		\Ni-Ru-Pt
		\Y-Er-Sc
		\Ca-Lu-Mo
		\Al-Zn-Sn
		\Th-V-Mo
		\Fe-Ni-Pd
		\Tb-Sm-Mo
		\Li-Pu-Ta
		\Pm-Dy-Tm
		\Fe-Co-Cu
		\Gd-Th-V
		\Dy-Ti-V
		\La-Ce-Gd
		\Yb-Th-Sc
		\Tb-Ho-Sc
		\Tb-Y-Zr
		\Tc-Ir-Rh
		\Ce-Nd-Ti
		\Yb-Y-U
		\Na-V-Cr
		\Nd-Lu-Th
		\Ho-Pu-Zr
		\Ba-Ca-Y
		\Fe-Os-Ru
		\Pm-Ho-Th
		\Gd-Th-Cr
		\Tb-Dy-Ti
		\La-Sc-V
		\Sr-Mn-Fe
		\La-Mo-W
		\Dy-Lu-Ta
		\Tl-Zn-Si
		\Nb-Tl-Cu
		\Pu-Np-Cr
		\Ir-Pt-C
		\Yb-Dy-Ti
		\Li-Pu-Mo
		\Pr-Tm-Mo
		\Nd-Dy-Er
		\Ce-Tm-V
		\Be-Al-Si
		\Dy-Th-V
		\Al-Cd-Si
		\La-Er-Nb
		\Tb-Nd-Ta
		\Eu-Hf-Nb
		\Re-Os-Pd
		\Nd-Tm-Cr
		\Sm-Tm-Th
		\Cs-Ta-V
		\Tb-Pu-Mo
		\Mg-Mn-V
		\Cu-Pd-Pt
		\Li-Er-Hf
		\Li-Lu-Ta
		\Y-Lu-Pu
		\Yb-Ho-Pu
		\Li-Sm-Y
		\Dy-Ti-Mo
		\Ca-Ti-V
		\Pr-Hf-U
		\La-Yb-Gd
		\Eu-Mn-V
		\Tb-Ti-Cr
		\Yb-Nd-Cr
		\Ti-Cr-Mo
		\Mg-Zr-Nb
		\Ta-Ti-Nb
		\Li-Nd-Sm
		\Tl-Ag-Pb
		\Tb-Gd-V
		\Gd-Pu-W
		\Cu-Pd-Rh
		\Li-Zr-Sc
		\Gd-Cr-W
		\Cu-Ru-Pt
		\Li-Er-Cr
		\Yb-Sc-Mo
		\Gd-Er-Cr
		\Pr-Lu-Pu
		\V-Fe-Bi
		\La-Gd-Ta
		\Ta-V-Bi
		\Tb-Cr-Mo
		\Cr-Mo-Au
		\Tb-Ce-Lu
		\Ca-La-Fe
		\Gd-Y-Cr
		\Cr-Fe-Pb
		\Cr-Bi-Mo
		\Ce-Pr-Mo
		\Sr-Sc-Ti
		\Ag-Ge-Bi
		\Y-Ta-V
		\Er-V-Cr
		\Sm-U-Nb
		\Cr-Hg-Mo
		\La-Pr-Pu
		\Ca-Ce-V
		\Th-V-W
		\Ba-Nd-Sc
		\Ce-Eu-Sc
		\Y-Ta-W
		\Cs-Li-Hf
		\Tb-Nd-Gd
		\K-Rb-Mo
		\Ca-Tm-Lu
		\Tb-Dy-Th
		\Ce-Pu-Ta
		\Yb-Hf-Nb
		\Y-Sc-W
		\Gd-Dy-Cr
		\Yb-Pu-Mo
		\Ce-Pr-Nd
		\Dy-Er-Mo
		\Y-Er-Zr
		\Hf-Th-Sc
		\Cu-Mo-Pb
		\Ho-Pu-Cr
		\Na-Mg-Mo
		\Cu-Re-Ru
		\Si-Ag-Ge
		\Ba-La-Eu
		\Pm-Lu-Th
		\Mn-V-Fe
		\Ca-La-Pm
		\Pr-U-V
		\Nd-Er-Tm
		\Re-Ni-Os
		\Gd-Y-Ti
		\Ce-Hf-Sc
		\La-Nd-Ti
		\Yb-Ho-Sc
		\Al-Cd-Bi
		\Al-Tl-Si
		\Tb-Sm-Lu
		\Pm-Sm-Er
		\Ce-Ti-W
		\Pr-Gd-Y
		\Dy-Cr-W
		\Zn-Sn-Bi
		\Rb-Mg-Mo
		\Tl-In-Mo
		\Ca-V-Fe
		\Th-Ta-Nb
		\Gd-Er-Ti
		\La-Er-W
		\Nd-Th-U
		\Ce-Pm-V
		\Tb-Y-Pu
		\Gd-Th-Mo
		\Yb-Pr-Mo
		\Yb-Hf-Ti
		\Rb-Ta-Ti
		\Dy-Pu-Ti
		\Be-Ga-Sn
		\Y-Ho-Pu
		\Ba-Yb-V
		\Yb-Y-Sc
		\Tb-Nd-Y
		\Ho-Zr-Ta
		\Pm-Gd-Tm
		\Li-Mn-V
		\Ce-Eu-Mn
		\Be-Al-Zn
		\Zn-Ge-Pb
		\Pr-Ho-Ta
		\Tb-Y-Ho
		\Li-Sm-Lu
		\Dy-Pu-Mo
		\Ba-Ca-Mn
		\Ho-Th-Cr
		\Er-Hf-Nb
		\Ca-Pu-V
		\Os-Pd-Pt
		\Pr-Pu-W
		\Cr-Ag-Pb
		\La-Ce-Sc
		\Mn-Cu-Pb
		\Yb-Sc-Nb
		\La-Nd-Ta
		\Ce-Ho-W
		\Re-Ag-Rh
		\Tl-Mo-Pb
		\Sm-V-Cr
		\Co-Ir-Rh
		\Pm-Gd-Pu
		\Ce-Er-Ti
		\Ce-Sm-Ta
		\La-Tb-Cr
		\Yb-Gd-Ti
		\Cu-Ni-Os
		\Rb-V-Cr
		\Yb-Zr-Ta
		\Nd-Sc-V
		\Tl-Cd-Pb
		\Tb-Sm-Gd
		\Sm-Gd-Dy
		\Tb-Yb-Dy
		\Li-Er-Mo
		\Pr-Er-Cr
		\Mg-U-Cr
		\La-Ce-Ho
		\Th-Sc-Nb
		\Nd-Gd-Y
		\Eu-Hf-Sc
		\Ce-Sc-Ti
		\Gd-Pu-V
		\Er-Ti-W
		\Ca-Pm-Lu
		\Co-Cu-Ru
		\Yb-Eu-Zr
		\Sm-Lu-W
		\Y-U-Ta
		\Y-Th-Sc
		\V-Mo-W
		\Ce-Tm-Mo
		\Cs-Ti-Cr
		\Nd-Dy-Ti
		\Li-Ho-Sc
		\Si-Ag-Au
		\Yb-Sc-W
		\Tl-Cd-Ga
		\Tb-Th-Sc
		\Ce-Cr-Mo
		\Yb-Pr-Er
		\Nd-Th-Zr
		\Pr-Nd-Sm
		\Li-Sc-Cr
		\Yb-Ho-Th
		\Tb-Ho-Cr
		\La-Pu-W
		\Nd-Dy-Y
		\Pm-Nd-Tm
		\Li-Tb-Pr
		\Gd-Ta-Mo
		\Li-La-Ti
		\Pu-Ti-W
		\Na-Ca-V
		\Y-Nb-W
		\Ce-Y-Mo
		\Hf-Sc-Nb
		\La-Tm-V
		\Ho-Lu-V
		\Ca-Sc-Ti
		\Nd-Sc-W
		\La-Tb-Sc
		\Yb-Gd-Dy
		\Tc-Pd-C
		\Li-V-Cr
		\Li-Sm-Nb
		\Be-Ge-Bi
		\Ca-Tb-V
		\K-Nb-Mo
		\Pm-Gd-Cr
		\La-Sm-U
		\Ca-Yb-V
		\Nd-Gd-Th
		\Pm-Pu-Mo
		\Yb-Th-Cr
		\Ce-Hf-Ti
		\Dy-Tm-Ti
		\Yb-Ta-W
		\Cu-Pd-Au
		\La-Tb-Ho
		\Tm-Pu-Cr
		\Tb-Ho-Th
		\La-Sc-Ta
		\Sm-Ta-Nb
		\Li-Mn-Fe
		\Ce-Gd-W
		\Sc-Nb-W
		\Ta-V-W
		\Nd-Sm-Dy
		\Nd-Ho-Pu
		\La-Er-Mo
		\La-Y-Ta
		\Ca-Nd-V
		\Re-Hg-Os
		\Tb-Dy-V
		\La-Tb-Nd
		\La-Zr-Sc
		\Cu-Tc-Rh
		\Li-Tm-Cr
		\Si-Ge-Pb
		\Gd-Y-Ta
		\Sm-Ta-Mo
		\Na-Mg-Fe
		\La-Ce-W
		\Th-Nb-V
		\Y-Nb-Mo
		\La-Ta-Mo
		\Dy-Tm-Pu
		\La-Pr-V
		\Ca-La-Ti
		\Pr-Dy-W
		\Ce-Nd-Cr
		\Lu-Zr-Ta
		\Y-Pu-Ti
		\Li-Ce-Mo
		\La-Eu-Sc
		\Nd-Sm-U
		\La-Tb-Zr
		\Li-Ce-Nb
		\Dy-Y-Lu
		\Zn-Ga-B
		\Y-Zr-Sc
		\Li-Pm-Y
		\Pm-Nd-Gd
		\Pr-Ti-W
		\Ba-La-Pr
		\Fe-Co-Pd
		\Pr-Nd-Ta
		\Tl-In-Ga
		\Li-Pm-V
		\Nd-Lu-Ta
		\Pr-Nd-Tm
		\Pr-Th-Ti
		\Ce-Y-Cr
		\Tb-Ce-Ta
		\V-Cr-Ag
		\Fe-Rh-C
		\Cs-K-Zr
		\Er-Tm-Cr
		\Tb-Y-Lu
		\K-Cr-Mo
		\Gd-Ti-V
		\La-Th-Ta
		\Yb-Ta-Mo
		\Ho-Th-Mo
		\Pr-Ho-Cr
		\Mg-V-Fe
		\Ta-Nb-V
		\Li-Mg-Mo
		\Nd-Lu-Zr
		\Tm-Th-Ti
		\K-Hf-Mg
		\Cs-Mg-Mo
		\Pr-Ta-W
		\Ba-Gd-Ti
		\Sm-Er-Nb
		\Yb-Eu-Mo
		\Pr-Ti-Cr
		\La-Pm-Sm
		\Li-Nd-Ti
		\Yb-Dy-Pu
		\Li-Gd-Zr
		\Ta-Nb-Tl
		\Fe-Cu-Ru
		\Pm-Pr-Sm
		\Fe-Cu-Pb
		\Dy-Th-Ta
		\Nb-Hg-Bi
		\Er-V-W
		\Sr-Y-V
		\Dy-Th-W
		\Tb-Yb-Ho
		\Nd-Y-W
		\Be-Zn-Sn
		\Eu-Y-Ta
		\Yb-Y-Ti
		\Ce-Gd-Mo
		\Gd-Th-Zr
		\Tl-Si-Ge
		\Sm-Dy-Pu
		\La-Ho-W
		\Cs-Li-Ti
		\Cu-Bi-Mo
		\Yb-Y-Er
		\Ce-U-Cr
		\Li-V-Cu
		\Gd-Er-Sc
		\Yb-Dy-Ho
		\Re-Ni-Ag
		\Ca-Nd-Ti
		\Yb-Pu-Ti
		\La-Tb-Mo
		\Ir-Rh-C
		\Al-Tl-Ga
		\La-Ti-Nb
		\Nd-Gd-Pu
		\Al-Cd-Ge
		\Li-Ho-Ta
		\Ho-V-Cr
		\Li-Gd-Sc
		\Ga-Re-Bi
		\Yb-Lu-Zr
		\Li-Pr-V
		\U-Ta-W
		\Yb-Tm-Pu
		\Ce-Ho-Lu
		\Fe-Ir-Pd
		\Gd-Pu-Ti
		\Tb-Gd-Th
		\Ca-La-V
		\Ce-Nd-Pu
		\Yb-Eu-U
		\Ta-V-Mo
		\Th-Nb-Mo
		\Ca-Tm-Mo
		\Er-Sc-W
		\Sm-Cr-W
		\Ce-Pm-Cr
		\Pr-Sm-Ta
		\Ta-Tl-In
		\Li-Tb-Pm
		\Ba-Pm-Pr
		\Li-Gd-Ho
		\Hg-B-Sb
		\Gd-Tm-Lu
		\Tb-Pr-Dy
		\Ce-Gd-Cr
		\Ho-Tm-Mo
		\Y-U-V
		\Ir-Pt-Rh
		\La-Tb-Yb
		\Li-Ti-V
		\Tm-Lu-Pu
		\Tb-Pr-Nd
		\Pr-Ta-V
		\Re-Bi-Os
		\Co-Cu-Bi
		\Nd-Ho-Cr
		\La-Pm-Tm
		\Ca-Eu-V
		\Ba-Sc-Ti
		\Na-Nb-Cu
		\Cs-Li-Zr
		\Gd-Dy-Lu
		\La-Ce-Cr
		\Cu-Re-Os
		\Li-Mg-Zr
		\Ce-Pu-Zr
		\Ca-La-Yb
		\Ce-Y-Pu
		\Zn-Cd-Ga
		\Nd-V-W
		\V-Fe-Ru
		\Tb-Dy-Er
		\Hf-Th-U
		\Sc-V-W
		\La-Y-Pu
		\Al-In-Si
		\Be-Ga-Ge
		\Os-Ru-Pt
		\Ag-Ge-Pb
		\Pm-Y-Tm
		\Li-La-Ho
		\Cs-Zr-Ti
		\Ce-Sc-Mo
		\Pr-Nd-Mo
		\Li-Tm-Ti
		\La-Ho-Sc
		\Nd-Tm-Mo
		\Th-Ta-Mo
		\Er-Tm-Mo
		\Gd-Y-W
		\Lu-Ta-V
		\Lu-Th-Ta
		\Ce-Er-Cr
		\Ce-Ti-Mo
		\Li-Ce-Gd
		\Ce-Th-U
		\Li-Hf-Nb
		\Yb-Ho-Ta
		\Li-Pm-Cr
		\Ce-Gd-V
		\Cs-K-Cr
		\Tb-Ti-W
		\Ce-Tm-Cr
		\Ho-Lu-Pu
		\Y-Er-Ti
		\Gd-Dy-Ti
		\Gd-Pu-Zr
		\Li-Cr-Cu
		\Co-Bi-Os
		\Gd-Pu-Ta
		\Co-Ru-Rh
		\Gd-Lu-Ti
		\Li-La-Ce
		\Be-Al-Ge
		\Gd-Y-Lu
		\Pr-Dy-Tm
		\Yb-Cr-Mo
		\La-Gd-Zr
		\Tl-Cu-Pb
		\Cr-Ag-Mo
		\Tb-Tm-Ti
		\Nd-Y-Pu
		\Eu-Th-V
		\Ga-Ge-Pb
		\Li-Pm-Lu
		\Li-Nd-Lu
		\Ba-Pr-Ti
		\Li-V-Mo
		\Pr-Y-Mo
		\Ce-V-Cr
		\Dy-Er-V
		\Ca-Eu-Pu
		\Ni-Pd-Pt
		\Er-Lu-Ta
		\Ba-Y-V
		\Pr-Nd-Lu
		\Ce-Nd-Y
		\Na-Mg-Ti
		\Cu-Ag-Ir
		\La-Ce-Mo
		\La-Er-Lu
		\Tc-Ni-Pd
		\Ti-Nb-V
		\Tb-Ce-Gd
		\Hf-U-Ti
		\Lu-V-Mo
		\Ta-V-In
		\Co-Ir-Pt
		\Na-Ti-Nb
		\Yb-Hf-Th
		\Mn-Cu-Ag
		\Ce-Nd-U
		\Pr-Gd-Ta
		\Ca-Pm-V
		\Nd-Gd-Cr
		\Yb-Eu-V
		\Al-Hg-Pb
		\V-Cr-Bi
		\Ce-Nd-Ho
		\Ho-Er-Th
		\Tb-Nd-Mo
		\Ca-Tb-Pm
		\Nd-Ho-Ta
		\Pu-Ta-W
		\V-Cu-Mo
		\Tb-Cr-W
		\V-Cu-Ag
		\Fe-Ni-Os
		\Lu-Th-V
		\Fe-Ni-C
		\Ca-Y-Sc
		\Ca-Ce-Eu
		\Rb-Ti-Cr
		\Nd-Sm-Th
		\Co-Re-Bi
		\Gd-Lu-V
		\Eu-Pu-Ta
		\Pu-Zr-Ti
		\Pm-Sm-Y
		\La-Ce-Er
		\Ga-Sn-Bi
		\Ba-Sr-Sc
		\Co-Re-Hg
		\La-Lu-W
		\Cs-Nb-Mo
		\La-Y-V
		\Ce-Sm-Er
		\Co-Ni-Ru
		\Mg-Cr-Mo
		\Sc-Nb-Mo
		\Sm-U-W
		\Ce-Ta-Ti
		\Li-Y-Pu
		\Tb-Dy-Pu
		\Cs-Rb-Cr
		\Cs-Rb-V
		\Ce-Gd-Ta
		\Mg-Ti-Mo
		\K-Ta-V
		\Rb-Na-Mg
		\Ba-Gd-V
		\Ca-Tb-Mo
		\Pr-Er-Hf
		\Si-Ge-Bi
		\Y-Er-Hf
		\Zn-Si-Bi
		\Li-Pm-Mo
		\Yb-Lu-Pu
		\La-Dy-Lu
		\La-Pm-Er
		\Hf-Zr-Ta
		\K-Hf-Nb
		\Lu-Ti-W
		\Li-Pu-Zr
		\Cd-Ga-Ge
		\Cd-Ga-B
		\Gd-Y-V
		\Cu-W-Au
		\Yb-Nd-Lu
		\Er-Tm-V
		\Tb-Gd-Y
		\Er-Lu-Mo
		\Tc-Pt-Rh
		\Dy-Ta-Ti
		\Cu-Pd-C
		\Y-V-Mo
		\La-Yb-Dy
		\Lu-Nb-W
		\Cr-Cu-Au
		\Y-Sc-Nb
		\Li-La-Nd
		\Tb-Sm-Pu
		\Tb-Y-Tm
		\Ce-Er-Sc
		\Gd-Dy-Mo
		\Ca-Ce-Nd
		\Ta-Tl-Mo
		\Pr-Nd-Er
		\Li-Pr-Nd
		\La-Pr-Ta
		\Tm-Cr-W
		\Ce-Ho-V
		\Ba-Yb-Ti
		\Na-Ti-Cr
		\Er-Th-Sc
		\Er-Ti-V
		\Cd-Ge-Pb
		\Ba-Eu-Mn
		\Re-Ag-Ir
		\Pr-Ti-V
		\Lu-Ta-Nb
		\Fe-Co-Ag
		\Li-Mn-Ni
		\Nd-Ho-Zr
		\La-Pm-Cr
		\Mg-Nb-V
		\Ce-Pm-Tm
		\Pd-Ru-Pt
		\Gd-Sc-Mo
		\Nd-Pu-Ti
		\Pr-Gd-Mo
		\Sc-U-W
		\Co-Cu-Rh
		\Eu-V-Cr
		\Y-Tm-Th
		\Tb-Lu-Ti
		\La-Nd-Y
		\Th-Ti-W
		\Li-Nd-Cr
		\Pm-Gd-Th
		\Tl-Cu-Ni
		\Pr-U-Cr
		\Ca-Tb-Y
		\Co-Pd-Rh
		\Li-Pm-Nd
		\Cu-Os-Rh
		\Tb-Pu-Cr
		\Ni-Ag-Ru
		\Li-Gd-V
		\Na-Hf-Ta
		\La-Sm-Ta
		\Ca-La-Nd
		\Sr-Ca-Mn
		\La-Mn-Fe
		\La-Th-Sc
		\Tb-Ce-Mo
		\Mo-Pb-W
		\Tb-Y-Cr
		\Al-Ga-Ge
		\V-Fe-Pd
		\Nd-Cr-W
		\Tl-Si-Ag
		\Tb-Ce-Pu
		\Cs-Pr-Mo
		\Nd-U-Ta
		\Eu-Ta-Nb
		\La-Dy-Th
		\Mg-Ti-V
		\Li-Fe-Cu
		\Na-Li-Ti
		\La-Tb-Pu
		\Th-Zr-Sc
		\Ce-Gd-Y
		\Dy-Lu-Cr
		\Nd-Sc-Ti
		\Yb-Nb-V
		\Ga-Si-Pb
		\Pr-Lu-Ti
		\Eu-Y-Cr
		\Hf-U-Nb
		\Pu-Ti-V
		\Li-Nd-Er
		\Cu-Ag-Bi
		\Sm-Nb-V
		\Gd-Dy-Y
		\Ho-Ta-Mo
		\Cu-Ag-Pd
		\Tc-Ni-Pt
		\Y-Lu-Nb
		\Ni-Os-Ru
		\Dy-Lu-Pu
		\Fe-Cu-Os
		\Ta-Tl-V
		\Eu-Y-U
		\Co-Pd-Ru
		\Re-Hg-Bi
		\La-Yb-Ho
		\Nd-Er-Lu
		\Tb-Yb-Er
		\Yb-Th-Zr
		\K-Li-Ti
		\Re-Tc-Rh
		\Cs-K-Al
		\Pr-Ti-Mo
		\Yb-Eu-Sc
		\Y-Th-Ta
		\Yb-Ti-V
		\Nd-Gd-V
		\Sm-Dy-Tm
		\Y-Mo-W
		\Ce-Er-W
		\Re-Tc-Pd
		\Ce-Nd-Zr
		\Li-Ho-Tm
		\Y-Tm-W
		\Al-Tl-Zn
		\Li-La-Mo
		\Pr-Ho-Tm
		\Eu-Th-Nb
		\Pr-Nd-Cr
		\Pm-Gd-Dy
		\Na-Ta-V
		\Al-Zn-Ga
		\Ba-Sm-Mo
		\Y-Ti-Cr
		\Nd-Th-Cr
		\Co-Ir-Pd
		\Yb-Gd-Ho
		\Hg-Ge-B
		\Ho-Tm-V
		\Pr-Tm-Ta
		\Tb-Dy-Cr
		\Ir-Ru-Pt
		\Ce-Pm-Pu
		\Tm-Pu-W
		\Pm-Nd-Th
		\Yb-Gd-Mo
		\La-Ti-V
		\Sm-Pu-Nb
		\Hf-Th-Zr
		\Ta-Ti-V
		\Be-Sn-Bi
		\Pm-Ho-Cr
		\Yb-Eu-Th
		\Ho-Lu-Mo
		\Tb-Dy-Ta
		\Ce-Y-Ti
		\Al-Zn-Pb
		\Ca-La-Mn
		\Yb-Eu-Ta
		\Cs-Hf-Zr
		\Na-Ca-Fe
		\Tb-Pu-Ta
		\Sr-Sc-Mo
		\Tc-Ir-C
		\Y-Hf-Sc
		\Ti-Mo-W
		\Pr-Gd-Dy
		\Co-Cu-Re
		\La-Y-U
		\Re-Sn-Bi
		\Yb-Ti-Cr
		\Nd-Mo-W
		\Fe-Ni-Ir
		\Pr-Nd-W
		\Yb-Lu-V
		\Ce-Pu-W
		\Ac-Cr-Mo
		\Zn-B-Pb
		\La-Yb-Tm
		\Ba-Nd-Mo
		\Mn-Co-Ag
		\Dy-Cr-Mo
		\Er-Tm-Lu
		\La-Y-Nb
		\Tc-Ir-Pt
		\Pr-Er-W
		\Yb-Ho-Cr
		\Mn-Fe-Au
		\Ca-Y-V
		\Pd-Ru-Au
		\Ce-Hf-U
		\La-Pm-Pr
		\Tb-V-Mo
		\Ba-Eu-Sc
		\Ta-Bi-Mo
		\Nd-Dy-V
		\Tl-B-Pb
		\Li-Hf-Zr
		\Li-La-Fe
		\Al-Sn-Bi
		\Ce-Pm-Er
		\Ni-Au-C
		\Er-Ta-W
		\Pr-Er-Pu
		\La-Nd-Sc
		\Ni-Ag-Ir
		\La-Ce-Th
		\La-Yb-Nd
		\Pm-Nd-Dy
		\Ce-Th-Mo
		\Eu-U-W
		\Pm-Ho-Er
		\Ce-Lu-Mo
		\Li-Cu-Mo
		\Sm-Y-Th
		\Tb-Nd-Dy
		\Nd-Er-Pu
		\Rb-Nb-V
		\Pr-Y-U
		\Y-Er-Pu
		\Cd-Mo-Pb
		\Li-Er-Zr
		\V-Cu-W
		\Pm-Nd-Mo
		\Yb-Er-Ti
		\Tb-Sc-Ta
		\Ca-Ti-Mo
		\Tb-Tm-Pu
		\Y-Lu-Ta
		\Rb-Li-Ti
		\Hg-Sb-Pb
		\La-Dy-Pu
		\Pd-Rh-Au
		\K-Ta-Ti
		\Li-La-Sc
		\Pr-Dy-Ti
		\Yb-Pr-W
		\La-Nd-Ho
		\Sm-Nb-W
		\Ce-Eu-Pu
		\Nd-Sc-Mo
		\Ag-Ir-Ru
		\Pm-Y-Ho
		\Yb-Dy-Lu
		\U-Ta-V
		\Ca-Tb-Ti
		\Tb-Yb-Lu
		\Ce-Pm-Sm
		\Sr-Nd-V
		\Co-Ni-Pt
		\Rb-Mg-Zr
		\Ti-V-Cr
		\Th-U-Cr
		\Yb-Pu-Cr
		\Ta-Cu-Bi
		\La-Yb-Cr
		\Pr-Ho-V
		\Rb-Na-Cr
		\Ce-Ta-V
		\Ce-Ta-W
		\Eu-Y-Sc
		\La-Ho-Ta
		\Cd-Ga-Bi
		\Eu-Pu-Mo
		\Nd-Gd-Er
		\Na-Li-Mo
		\Tb-Tm-V
		\Th-Sc-U
		\Lu-Cr-Mo
		\Tb-Ti-V
		\Tb-Sm-Ho
		\Dy-Ta-V
		\Rb-Li-Nb
		\Li-Ta-Mo
		\Re-Tc-Pt
		\Pm-Gd-V
		\La-Gd-Y
		\La-Dy-Ta
		\Mg-Ti-Nb
		\Nd-Th-W
		\Dy-Y-Zr
		\Tb-Zr-Ti
		\Pd-Pt-W
		\Cs-Rb-Ti
		\Ca-Nd-Pu
		\La-Pm-Nd
		\Sm-Ho-Pu
		\Hg-Bi-Mo
		\Co-Ag-Pb
		\Ce-Pm-Pr
		\Cu-Re-Pd
		\La-Th-Mo
		\Ba-Pr-Nd
		\La-Yb-W
		\Li-Y-Sc
		\Th-U-Ta
		\Li-Tm-Mo
		\Zn-Si-Ge
		\Sc-Nb-V
		\Sm-Th-V
		\Li-La-Pm
		\Li-Y-Ho
		\Y-Hf-U
		\Ti-V-W
		\Sm-Lu-Th
		\Li-Cu-Ni
		\Ce-Y-Lu
		\Tm-Th-W
		\Yb-Tm-Mo
		\Ca-Yb-Nd
		\Ce-Eu-V
		\Nd-Er-Sc
		\Hf-Ta-Nb
		\Co-Ag-C
		\Co-Ir-Os
		\Ca-Mn-V
		\Pu-Ta-Nb
		\V-Bi-Mo
		\Y-Hf-Th
		\Dy-Mo-W
		\Ce-Sm-Lu
		\Ni-Ir-Pd
		\Re-Os-Rh
		\Ce-Nd-Mo
		\Er-Hf-Sc
		\Cr-Fe-Ag
		\Li-Nd-V
		\Eu-Sc-Ta
		\Yb-Tm-Th
		\Ga-Ge-B
		\Eu-Sc-W
		\Li-Pr-Gd
		\Yb-Pr-Dy
		\Sm-Pu-V
		\La-Nd-Cr
		\Ce-Ho-Mo
		\Li-Pm-Pu
		\Y-Sc-U
		\Pr-Sm-Mo
		\Pr-U-Ta
		\Re-Tc-Ir
		\Nd-Zr-Ta
		\Tl-In-Si
		\Yb-V-Cr
		\Tb-Pr-Sm
		\Mn-Fe-Ru
		\Ce-Zr-Ta
		\K-Ti-Cr
		\Ce-Sm-Mo
		\Ni-Ag-Pb
		\K-Mg-Cr
		\Tb-Nd-Cr
		\Pu-Cr-W
		\La-Gd-Ho
		\Pm-Nd-Lu
		\La-Dy-Y
		\Tb-Th-V
		\Cu-Tc-Ir
		\Li-Tb-Ho
		\Tb-Sm-Tm
		\Sr-Nd-Y
		\Pm-V-Mo
		\K-Rb-Ta
		\Gd-Sc-Ti
		\Y-Er-Cr
		\Ce-Eu-Th
		\Yb-Nd-Dy
		\Er-Hf-Ta
		\Pr-Y-Th
		\Eu-Zr-Sc
		\Gd-Sc-Ta
		\Li-Ce-Ti
		\Na-Hf-Nb
		\Sm-Gd-Cr
		\La-Pr-Nd
		\Ga-Re-Hg
		\Li-La-Gd
		\Co-Ni-Rh
		\La-Tb-Er
		\Yb-Lu-Mo
		\Er-Pu-V
		\Tl-Ga-Pb
		\Yb-Sc-V
		\Co-Ag-Ru
		\Li-Zr-Nb
		\Rb-Ta-V
		\K-Li-Mg
		\Dy-V-W
		\Pu-Mo-W
		\Li-La-Mn
		\Tb-Zr-Sc
		\La-Pr-Er
		\Tb-Lu-Ta
		\Tb-Ho-Pu
		\Ba-Ca-Mo
		\Gd-Th-Ti
		\Ta-Nb-Mo
		\La-Y-Sc
		\La-Tm-Mo
		\Ge-B-Pb
		\Li-Pr-Ta
		\Cu-Pt-Au
		\La-Pu-V
		\Tb-Pr-Gd
		\Eu-Ta-V
		\Ce-Tm-W
		\Pr-Tm-W
		\La-Gd-Ti
		\Pm-Pr-Th
		\Sm-Tm-Cr
		\Mn-Ni-Au
		\Ce-Y-V
		\Rb-Cr-Mo
		\La-Nb-W
		\Ca-Y-Mo
		\Sm-Lu-Nb
		\Li-V-Fe
		\Y-Er-Nb
		\Nd-Dy-Zr
		\Pm-Pr-Tm
		\Mg-U-Nb
		\La-Yb-Eu
		\Gd-V-W
		\Eu-Zr-Ta
		\Tb-Ho-Er
		\Cs-Rb-Nb
		\Tb-Pr-Lu
		\Cd-Bi-B
		\Cu-Ir-Ru
		\Gd-Ho-Er
		\Pr-V-Mo
		\La-Nd-Gd
		\Y-Tm-Ti
		\Ni-Ag-Au
		\K-Hf-Ti
		\Nd-Ho-Tm
		\Pr-Nd-Pu
		\La-Gd-V
		\Yb-Er-Cr
		\Dy-V-Mo
		\La-Nd-Dy
		\Ce-Pr-Lu
		\Ce-Nd-Gd
		\Y-Th-Zr
		\Tl-Ni-Ag
		\Nd-Gd-Ti
		\K-Li-V
		\Li-Ce-Y
		\Mn-Ni-Ru
		\Ho-Pu-W
		\Pm-Dy-Lu
		\Ce-Sm-U
		\Ni-Os-Pd
		\Cr-Mo-Pb
		\Er-Ta-V
		\Ce-Nd-Er
		\Zn-Ga-Si
		\Sm-Y-Ta
		\Eu-Nb-W
		\Fe-Pd-Au
		\Co-Re-Ru
		\Sm-Y-Tm
		\Fe-Cu-C
		\Tm-Pu-Ti
		\Ta-Nb-Cu
		\Ho-Ta-V
		\Li-Lu-Ti
		\Yb-Er-Nb
		\Fe-Ni-Au
		\Nd-Ta-W
		\La-Pr-Ho
		\Mg-V-Cr
		\La-Pr-U
		\Y-Zr-Ti
		\Cu-Ni-Au
		\Rb-Li-Mo
		\Li-Ho-Zr
		\Fe-Ag-Pb
		\Eu-Y-V
		\Nd-Th-Ti
		\Pm-Pr-Cr
		\Sm-Y-Mo
		\Zn-Ga-Sn
		\Tl-Ga-Si
		\La-Tb-Y
		\Tm-Ta-Ti
		\Sr-Nd-Mo
		\Y-Tm-Mo
		\Tb-Yb-Pu
		\La-Ho-Mo
		\Dy-Ho-Mo
		\Cs-Hf-Mg
		\Ba-Sm-V
		\Pr-Y-Lu
		\Nd-Er-V
		\Tm-Mo-W
		\Li-Ta-Nb
		\Gd-Tm-Th
		\Ti-V-Mo
		\Sc-Ta-Nb
		\La-Sm-Tm
		\Ce-Er-Tm
		\Y-Ho-Er
		\Sm-Th-Mo
		\Tb-Sm-Y
		\Li-Tb-Mo
		\Ho-Lu-Th
		\Sm-Pu-Ta
		\Nd-Tm-Ti
		\Na-Li-Ta
		\K-Ti-V
		\Mn-Tl-Pb
		\Hg-B-Pb
		\Er-Lu-Th
		\Pu-Zr-Nb
		\Ca-La-Ce
		\Eu-Hf-U
		\Os-Ru-Rh
		\La-Sc-Cr
		\V-Ag-Ru
		\Ba-Pr-Sm
		\Nd-Th-V
		\Pr-Ta-Ti
		\Y-Tm-Pu
		\Tb-Y-Th
		\Ce-Nd-Th
		\Pt-Rh-Au
		\Er-Nb-Mo
		\Ba-La-Mn
		\Pr-Pu-V
		\Sm-Y-Cr
		\Ga-Re-Sn
		\Ho-Er-Pu
		\Al-Tl-Ge
		\Dy-Lu-Mo
		\Na-Sr-Ti
		\Cd-Si-Ge
		\Tb-Pr-Pu
		\Ba-Gd-Sc
		\Ca-Tb-Pu
		\Cu-Ag-Os
		\Co-Cu-Ir
		\Pm-Dy-Pu
		\Zn-Cd-Ge
		\La-V-W
		\Sr-Ca-V
		\Dy-Ho-Tm
		\Eu-Th-W
		\Ca-Pm-Y
		\Eu-Th-Zr
		\Cu-Os-Ru
		\Be-In-Ga
		\La-Th-Ti
		\Nd-Pu-Cr
		\Ca-Ce-Y
		\Yb-Y-V
		\Ca-La-Y
		\Li-Tm-Pu
		\Al-Tl-Pb
		\Li-Ce-Ho
		\Li-Sc-Nb
		\Pr-Nd-Dy
		\Yb-Nd-Pu
		\Li-Ce-Nd
		\Pm-Dy-Cr
		\Dy-Lu-V
		\Ca-Mn-Fe
		\Cs-Mg-V
		\Ce-Eu-Nb
		\Tb-V-Cr
		\Ce-Ta-Nb
