namespace $.$$ {

	$mol_style_define( $mpds_visavis_lib_plotly_view, {

		flex: {
			grow: 1
		},

	} )

}
