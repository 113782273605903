$mpds_visavis_plot_legend_cmp $mol_view
	sub /
		<= Label*0 $mpds_visavis_plot_legend_cmp_label
			label <= label* \
			background <= background* \
		<= Intersection $mpds_visavis_plot_legend_cmp_label
			label \Intersection count
			background \gray
	labels /
	colorset /

$mpds_visavis_plot_legend_cmp_label $mol_view
	sub / <= label \
	style *
		^
		background <= background \
